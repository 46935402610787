var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("criacao-questionario"),
            active: _vm.questionnaireVideoIntroSteps,
          },
          on: {
            "update:active": function ($event) {
              _vm.questionnaireVideoIntroSteps = $event
            },
          },
        },
        [
          _c("iframe", {
            staticClass: "ql-video ql-align-center",
            attrs: {
              src: "https://www.youtube.com/embed/crY8H2viA3w",
              width: "100%",
              height: "300",
            },
          }),
        ]
      ),
      _c(
        "form-wizard",
        {
          ref: "formWizard",
          attrs: {
            color: "rgba(var(--vs-primary), 1)",
            title: null,
            subtitle: null,
            hideButtons: true,
            "step-size": "xs",
            enableAllNav: this.content !== null,
          },
          on: { "on-change": _vm.tabChanged },
        },
        [
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: { title: _vm.$t("formulario"), icon: "feather icon-home" },
            },
            [
              _vm.id === null
                ? _c("PlanUsageAlert", {
                    attrs: { target: "content_questionnaire" },
                  })
                : _vm._e(),
              _c("vx-tour", {
                attrs: {
                  name: "questionnaireIntroSteps",
                  auto: false,
                  delegate: _vm.ckeckVideoClosed,
                },
              }),
              _c("vx-card", { attrs: { title: _vm.$t("dados-basicos") } }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate:name",
                            value: "required|max:191",
                            expression: "'required|max:191'",
                            arg: "name",
                          },
                        ],
                        staticClass: "w-full required",
                        attrs: {
                          name: "model.name",
                          id: "model_name",
                          label: _vm.$t("nome-do-formulario"),
                        },
                        model: {
                          value: _vm.model.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "name", $$v)
                          },
                          expression: "model.name",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("name"),
                              expression: "errors.has('name')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v(_vm._s(_vm.errors.first("name")))]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-row mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
                    [
                      _c("label", { attrs: { id: "description" } }, [
                        _vm._v(_vm._s(_vm.$t("informacoes-gerais"))),
                      ]),
                      _c("ComplexEditor", {
                        attrs: {
                          questionnaire_id: _vm.contentId,
                          editor_data: _vm.editorData,
                          placeholder: _vm.$t("digite-aqui"),
                        },
                        on: {
                          "update:questionnaire_id": function ($event) {
                            _vm.contentId = $event
                          },
                          "update:editor_data": function ($event) {
                            _vm.editorData = $event
                          },
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("description"),
                              expression: "errors.has('description')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v(_vm._s(_vm.errors.first("description")))]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.$acl.hasPermission("disciplines.show")
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
                        [
                          _c(
                            "vx-input-group",
                            [
                              _c("vs-input", {
                                attrs: {
                                  disabled: "",
                                  value: _vm.disciplineName,
                                  label: _vm.$t("discipline"),
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("discipline_id"),
                                      expression: "errors.has('discipline_id')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("discipline_id"))
                                  ),
                                ]
                              ),
                              _c("template", { slot: "append" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "append-text btn-addon flex items-end",
                                  },
                                  [
                                    _c("vs-button", {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value:
                                            "questionnaires.discipline.search",
                                          expression:
                                            "'questionnaires.discipline.search'",
                                        },
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.top",
                                          value: {
                                            content: "Pesquisar disciplina",
                                            delay: { show: 1000, hide: 100 },
                                          },
                                          expression:
                                            "{\n                      content: 'Pesquisar disciplina',\n                      delay: { show: 1000, hide: 100 }\n                    }",
                                          modifiers: { top: true },
                                        },
                                      ],
                                      attrs: {
                                        color: "primary",
                                        type: "border",
                                        icon: "search",
                                        title: _vm.$t("buscar"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showDisciplineGrid = true
                                        },
                                      },
                                    }),
                                    _c("vs-button", {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value:
                                            "questionnaires.discipline.create",
                                          expression:
                                            "'questionnaires.discipline.create'",
                                        },
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.top",
                                          value: {
                                            content: "Criar nova disciplina",
                                            delay: { show: 1000, hide: 100 },
                                          },
                                          expression:
                                            "{\n                      content: 'Criar nova disciplina',\n                      delay: { show: 1000, hide: 100 }\n                    }",
                                          modifiers: { top: true },
                                        },
                                      ],
                                      attrs: {
                                        color: "primary",
                                        type: "border",
                                        icon: "add",
                                        title: _vm.$t("incluir"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showDisciplineForm = true
                                        },
                                      },
                                    }),
                                    _c("vs-button", {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value:
                                            "questionnaires.discipline.clear",
                                          expression:
                                            "'questionnaires.discipline.clear'",
                                        },
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.top",
                                          value: {
                                            content: "Limpar campo disciplina",
                                            delay: { show: 1000, hide: 100 },
                                          },
                                          expression:
                                            "{\n                      content: 'Limpar campo disciplina',\n                      delay: { show: 1000, hide: 100 }\n                    }",
                                          modifiers: { top: true },
                                        },
                                      ],
                                      attrs: {
                                        color: "primary",
                                        type: "border",
                                        icon: "clear",
                                        title: _vm.$t("action.clear"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setDiscipline(
                                            undefined,
                                            ""
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.$acl.hasPermission("content_questionnaire_templates.show")
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
                        [
                          _c(
                            "vx-input-group",
                            [
                              _c("vs-input", {
                                attrs: {
                                  disabled: "",
                                  value: _vm.templateName,
                                  label: "Template",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("template_id"),
                                      expression: "errors.has('template_id')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("template_id"))
                                  ),
                                ]
                              ),
                              _c("template", { slot: "append" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "append-text btn-addon flex items-end",
                                  },
                                  [
                                    _c("vs-button", {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value:
                                            "content_questionnaire_templates.grid",
                                          expression:
                                            "'content_questionnaire_templates.grid'",
                                        },
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.top",
                                          value: {
                                            content: "Pesquisar template",
                                            delay: { show: 1000, hide: 100 },
                                          },
                                          expression:
                                            "{\n                      content: 'Pesquisar template',\n                      delay: { show: 1000, hide: 100 }\n                    }",
                                          modifiers: { top: true },
                                        },
                                      ],
                                      attrs: {
                                        color: "primary",
                                        type: "border",
                                        icon: "search",
                                        title: _vm.$t("buscar"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showTemplateGrid = true
                                        },
                                      },
                                    }),
                                    _c("vs-button", {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value:
                                            "content_questionnaire_templates.grid",
                                          expression:
                                            "'content_questionnaire_templates.grid'",
                                        },
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.top",
                                          value: {
                                            content: "Limpar template",
                                            delay: { show: 1000, hide: 100 },
                                          },
                                          expression:
                                            "{\n                      content: 'Limpar template',\n                      delay: { show: 1000, hide: 100 }\n                    }",
                                          modifiers: { top: true },
                                        },
                                      ],
                                      attrs: {
                                        color: "primary",
                                        type: "border",
                                        icon: "clear",
                                        title: _vm.$t("limpar"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setTemplate(undefined, "")
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "vx-card",
                {
                  staticClass: "mt-2 mb-3 config",
                  attrs: {
                    title: _vm.$t("configuracao"),
                    collapseAction: "",
                    isContentCollapsedProp: _vm.configCollapsed,
                  },
                },
                [
                  _c("div", { staticClass: "grid grid-cols-1 gap-y-8" }, [
                    _c("div", { staticClass: "w-full" }, [
                      _c("div", { staticClass: "w-full pb-2" }, [
                        _c(
                          "label",
                          { staticClass: "block text-left font-medium" },
                          [_vm._v(_vm._s(_vm.$t("apresentacao")))]
                        ),
                        _c("hr", { staticClass: "border-faint-grey" }),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.$t("uma-questao-por-vez"))),
                              ]),
                              _c("vs-switch", {
                                on: {
                                  input: function ($event) {
                                    return _vm.changedPerQuestion($event)
                                  },
                                },
                                model: {
                                  value: _vm.model.single_question,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "single_question", $$v)
                                  },
                                  expression: "model.single_question",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("single_question"),
                                      expression:
                                        "errors.has('single_question')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("single_question"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.$t("nao-voltar"))),
                              ]),
                              _c("vs-switch", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.auto",
                                    value: {
                                      content:
                                        "Quando o questionário for apresentado em uma questão ou uma sessão por vez, o botão de voltar estará desabilitado.",
                                      delay: { show: 500, hide: 500 },
                                    },
                                    expression:
                                      "{\n                  content: 'Quando o questionário for apresentado em uma questão ou uma sessão por vez, o botão de voltar estará desabilitado.',\n                  delay: { show: 500, hide: 500 }\n                }",
                                    modifiers: { auto: true },
                                  },
                                ],
                                on: {
                                  input: function ($event) {
                                    return _vm.atLeast(
                                      $event,
                                      "should_have_at_least_one_grouping",
                                      "single_question",
                                      "single_section"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.model.disallow_question_return,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "disallow_question_return",
                                      $$v
                                    )
                                  },
                                  expression: "model.disallow_question_return",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has(
                                        "disallow_question_return"
                                      ),
                                      expression:
                                        "errors.has('disallow_question_return')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        "disallow_question_return"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.$t("uma-secao-por-vez"))),
                              ]),
                              _c("vs-switch", {
                                on: {
                                  input: function ($event) {
                                    return _vm.changedPerSection($event)
                                  },
                                },
                                model: {
                                  value: _vm.model.single_section,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "single_section", $$v)
                                  },
                                  expression: "model.single_section",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.$t("controle-por-voz"))),
                              ]),
                              _c("vs-switch", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.auto",
                                    value: {
                                      content:
                                        "Permite o usuário realizar o questionário utilizando somente a voz.",
                                      delay: { show: 500, hide: 500 },
                                    },
                                    expression:
                                      "{\n                    content: 'Permite o usuário realizar o questionário utilizando somente a voz.',\n                    delay: { show: 500, hide: 500 }\n                  }",
                                    modifiers: { auto: true },
                                  },
                                ],
                                model: {
                                  value: _vm.model.voice_control,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "voice_control", $$v)
                                  },
                                  expression: "model.voice_control",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("progressive-questionnaire"))
                                ),
                              ]),
                              _c("vs-switch", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.auto",
                                    value: {
                                      content:
                                        "Permite o usuário realizar o questionário utilizando somente a voz.",
                                      delay: { show: 500, hide: 500 },
                                    },
                                    expression:
                                      "{\n                    content: 'Permite o usuário realizar o questionário utilizando somente a voz.',\n                    delay: { show: 500, hide: 500 }\n                  }",
                                    modifiers: { auto: true },
                                  },
                                ],
                                model: {
                                  value: _vm.model.progressive_questionnaire,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "progressive_questionnaire",
                                      $$v
                                    )
                                  },
                                  expression: "model.progressive_questionnaire",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has(
                                        "progressive_questionnaire_return"
                                      ),
                                      expression:
                                        "errors.has('progressive_questionnaire_return')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        "progressive_questionnaire_return"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.model.progressive_questionnaire
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "tour-config-1 lg:row-start-auto",
                                },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["##########"],
                                        expression: "['##########']",
                                      },
                                    ],
                                    attrs: {
                                      type: "number",
                                      label: _vm.$t(
                                        "progressive-questionnaire-count"
                                      ),
                                    },
                                    model: {
                                      value:
                                        _vm.model
                                          .progressive_questionnaire_count,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.model,
                                          "progressive_questionnaire_count",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "model.progressive_questionnaire_count",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "w-full" }, [
                      _c("div", { staticClass: "w-full pb-2" }, [
                        _c(
                          "label",
                          { staticClass: "block text-left font-medium" },
                          [_vm._v(_vm._s(_vm.$t("resultado")))]
                        ),
                        _c("hr", { staticClass: "border-faint-grey" }),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4",
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: {
                                    content:
                                      "<div style='max-width: 200px'>Permite o participante visualizar as questões, notas e feedback das questões logo após finalizar o questionário. Quando esta opção esta desmarcada, ele só poderá visualizar estes dados após a liberação das respostas pelo professor.</div>",
                                    delay: { show: 1000, hide: 100 },
                                  },
                                  expression:
                                    "{\n                content: `<div style='max-width: 200px'>Permite o participante visualizar as questões, notas e feedback das questões logo após finalizar o questionário. Quando esta opção esta desmarcada, ele só poderá visualizar estes dados após a liberação das respostas pelo professor.</div>`,\n                delay: { show: 1000, hide: 100 }\n                }",
                                  modifiers: { top: true },
                                },
                              ],
                            },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.$t("previa-resultado"))),
                              ]),
                              _c("vs-switch", {
                                model: {
                                  value: _vm.model.view_results,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "view_results", $$v)
                                  },
                                  expression: "model.view_results",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: {
                                    content:
                                      "<div style='max-width: 200px'>Permite o participante visualizar a resposta padrão, caso esteja cadastrada na questão.</div>",
                                    delay: { show: 1000, hide: 100 },
                                  },
                                  expression:
                                    "{\n                content: `<div style='max-width: 200px'>Permite o participante visualizar a resposta padrão, caso esteja cadastrada na questão.</div>`,\n                delay: { show: 1000, hide: 100 }\n                }",
                                  modifiers: { top: true },
                                },
                              ],
                              staticClass: "tour-config-5",
                            },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.$t("visualizar-gabarito"))),
                              ]),
                              _c("vs-switch", {
                                model: {
                                  value: _vm.model.view_answers,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "view_answers", $$v)
                                  },
                                  expression: "model.view_answers",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: {
                                    content:
                                      "<div style='max-width: 200px'>Permite o participante visualizar sua posição ou ranking em relação aos outros participantes.</div>",
                                    delay: { show: 1000, hide: 100 },
                                  },
                                  expression:
                                    "{\n                content: `<div style='max-width: 200px'>Permite o participante visualizar sua posição ou ranking em relação aos outros participantes.</div>`,\n                delay: { show: 1000, hide: 100 }\n                }",
                                  modifiers: { top: true },
                                },
                              ],
                            },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.$t("visualizar-colocacao"))),
                              ]),
                              _c("vs-switch", {
                                model: {
                                  value: _vm.model.view_rank,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "view_rank", $$v)
                                  },
                                  expression: "model.view_rank",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: {
                                    content:
                                      "<div style='max-width: 200px'>Não apresenta as questões no resultado. Usar esta opção quando não desejar que visualizem o gabarito ou feedback antes de todos finalizarem.</div>",
                                    delay: { show: 1000, hide: 100 },
                                  },
                                  expression:
                                    "{\n                content: `<div style='max-width: 200px'>Não apresenta as questões no resultado. Usar esta opção quando não desejar que visualizem o gabarito ou feedback antes de todos finalizarem.</div>`,\n                delay: { show: 1000, hide: 100 }\n                }",
                                  modifiers: { top: true },
                                },
                              ],
                            },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("nao-apresentar-questoes"))
                                ),
                              ]),
                              _c("vs-switch", {
                                model: {
                                  value: _vm.model.hide_questions,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "hide_questions", $$v)
                                  },
                                  expression: "model.hide_questions",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "tour-config-1 lg:row-start-auto" },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["##########"],
                                    expression: "['##########']",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  label: _vm.$t("media-aprovacao"),
                                },
                                model: {
                                  value: _vm.model.average_score,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "average_score", $$v)
                                  },
                                  expression: "model.average_score",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.auto",
                                  value: {
                                    content:
                                      "<div style='max-width: 200px'>Pontuação padrão para cada questão. Se essa pontuação for configurada, todas as pontuações das questões serão substituídas por esse valor.</div>",
                                    delay: { show: 500, hide: 500 },
                                  },
                                  expression:
                                    "{\n                  content: `<div style='max-width: 200px'>Pontuação padrão para cada questão. Se essa pontuação for configurada, todas as pontuações das questões serão substituídas por esse valor.</div>`,\n                  delay: { show: 500, hide: 500 }\n                }",
                                  modifiers: { auto: true },
                                },
                              ],
                              staticClass: "tour-config-x3 lg:row-start-auto",
                            },
                            [
                              _c("vs-input", {
                                attrs: {
                                  type: "number",
                                  label: _vm.$t("score-default"),
                                },
                                model: {
                                  value: _vm.model.question_score,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "question_score", $$v)
                                  },
                                  expression: "model.question_score",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "questionnaires.config.score_level",
                                  expression:
                                    "'questionnaires.config.score_level'",
                                },
                              ],
                              staticClass: "lg:row-start-auto col-span-3",
                            },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.$t("score-level"))),
                              ]),
                              _c("vs-switch", {
                                model: {
                                  value: _vm.showScoreLevelConfig,
                                  callback: function ($$v) {
                                    _vm.showScoreLevelConfig = $$v
                                  },
                                  expression: "showScoreLevelConfig",
                                },
                              }),
                              _vm.showScoreLevelConfig
                                ? _c(
                                    "div",
                                    { staticClass: "pt-3" },
                                    [
                                      _c(
                                        "vs-button",
                                        {
                                          staticClass: "px-2 py-2 pt-1",
                                          attrs: {
                                            "text-color":
                                              "var(--content-color)",
                                            type: "flat",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showPopupScoreLevelConfig = true
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "cogs" },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "inline pl-1 font-semibold",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("action.config"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "w-full" }, [
                      _c("div", { staticClass: "w-full pb-2" }, [
                        _c(
                          "label",
                          { staticClass: "block text-left font-medium" },
                          [_vm._v(_vm._s(_vm.$t("seguranca")))]
                        ),
                        _c("hr", { staticClass: "border-faint-grey" }),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.$t("embaralhar-questoes"))),
                              ]),
                              _c("vs-switch", {
                                model: {
                                  value: _vm.model.shuffle_questions,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "shuffle_questions",
                                      $$v
                                    )
                                  },
                                  expression: "model.shuffle_questions",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("continuar-posteriormente"))
                                ),
                              ]),
                              _c("vs-switch", {
                                on: {
                                  input: function ($event) {
                                    return _vm.onlyOne(
                                      $event,
                                      "leave",
                                      "multiple_attempts"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.model.leave,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "leave", $$v)
                                  },
                                  expression: "model.leave",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("leave"),
                                      expression: "errors.has('leave')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [_vm._v(_vm._s(_vm.errors.first("leave")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.$t("multiplas-tentativas"))),
                              ]),
                              _c("vs-switch", {
                                on: {
                                  input: function ($event) {
                                    return _vm.onlyOne(
                                      $event,
                                      "multiple_attempts",
                                      "leave"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.model.multiple_attempts,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "multiple_attempts",
                                      $$v
                                    )
                                  },
                                  expression: "model.multiple_attempts",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("esconder-valor-da-questao"))
                                ),
                              ]),
                              _c("vs-switch", {
                                model: {
                                  value: _vm.model.hide_question_score,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "hide_question_score",
                                      $$v
                                    )
                                  },
                                  expression: "model.hide_question_score",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "sm:col-span-1 content-start" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.$t("somente-convidados"))),
                              ]),
                              _c("vs-switch", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.top",
                                    value: {
                                      content:
                                        "Somente usuários adicionados na lista de emails da publicação poderão responder esta avaliação.",
                                      delay: { show: 500, hide: 500 },
                                    },
                                    expression:
                                      "{\n                    content: 'Somente usuários adicionados na lista de emails da publicação poderão responder esta avaliação.',\n                    delay: { show: 500, hide: 500 },\n                  }",
                                    modifiers: { top: true },
                                  },
                                ],
                                model: {
                                  value: _vm.model.restricted_publication,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "restricted_publication",
                                      $$v
                                    )
                                  },
                                  expression: "model.restricted_publication",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "sm:col-span-1 content-start" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.$t("permitir-pausas"))),
                              ]),
                              _c("vs-switch", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.top",
                                    value: {
                                      classes: ["tooltip-max-width"],
                                      content:
                                        "Permitir que o usuário pause a avaliação com uma justificativa. A pausa não causará pausa no tempo da avaliação se estiver configurado e deverá ser justificada.",
                                      delay: { show: 500, hide: 500 },
                                    },
                                    expression:
                                      "{\n                    classes: ['tooltip-max-width'],\n                    content: 'Permitir que o usuário pause a avaliação com uma justificativa. A pausa não causará pausa no tempo da avaliação se estiver configurado e deverá ser justificada.',\n                    delay: { show: 500, hide: 500 },\n                  }",
                                    modifiers: { top: true },
                                  },
                                ],
                                on: { input: _vm.enforceCanContinue },
                                model: {
                                  value: _vm.model.can_pause,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "can_pause", $$v)
                                  },
                                  expression: "model.can_pause",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("can_pause"),
                                      expression: "errors.has('can_pause')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [_vm._v(_vm._s(_vm.errors.first("can_pause")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: {
                                    content: _vm.$t(
                                      "second_chance_description"
                                    ),
                                    delay: { show: 300, hide: 500 },
                                    classes: ["tooltip-max-width"],
                                  },
                                  expression:
                                    "{content: $t('second_chance_description'), delay: { show: 300, hide: 500 },classes: ['tooltip-max-width'],}",
                                  modifiers: { top: true },
                                },
                              ],
                            },
                            [
                              _c("label", { attrs: { for: "second_chance" } }, [
                                _vm._v(_vm._s(_vm.$t("second_chance"))),
                              ]),
                              _c("vs-switch", {
                                attrs: { id: "second_chance" },
                                model: {
                                  value: _vm.secondChance,
                                  callback: function ($$v) {
                                    _vm.secondChance = $$v
                                  },
                                  expression: "secondChance",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("second_chance"),
                                      expression: "errors.has('second_chance')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("second_chance"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.secondChance,
                                  expression: "secondChance",
                                },
                              ],
                            },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["###"],
                                    expression: "['###']",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  label: _vm.$t("second_chance_total"),
                                },
                                model: {
                                  value: _vm.second_chance.parameters.total,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.second_chance.parameters,
                                      "total",
                                      $$v
                                    )
                                  },
                                  expression: "second_chance.parameters.total",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has(
                                        "second_chance.parameters.total"
                                      ),
                                      expression:
                                        "errors.has('second_chance.parameters.total')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        "second_chance.parameters.total"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.secondChance,
                                  expression: "secondChance",
                                },
                              ],
                            },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["###"],
                                    expression: "['###']",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  label: _vm.$t("second_chance_min_score"),
                                },
                                model: {
                                  value: _vm.second_chance.parameters.min_score,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.second_chance.parameters,
                                      "min_score",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "second_chance.parameters.min_score",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has(
                                        "second_chance.parameters.min_score"
                                      ),
                                      expression:
                                        "errors.has('second_chance.parameters.min_score')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        "second_chance.parameters.min_score"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "questionnaires.config.facematch",
                                  expression:
                                    "'questionnaires.config.facematch'",
                                },
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: {
                                    content: _vm.$t("facematch_description"),
                                    delay: { show: 300, hide: 500 },
                                    classes: ["tooltip-max-width"],
                                  },
                                  expression:
                                    "{content: $t('facematch_description'), delay: { show: 300, hide: 500 },classes: ['tooltip-max-width'],}",
                                  modifiers: { top: true },
                                },
                              ],
                            },
                            [
                              _c("label", { attrs: { for: "facematch" } }, [
                                _vm._v(_vm._s(_vm.$t("facematch"))),
                              ]),
                              _c("vs-switch", {
                                attrs: { id: "facematch" },
                                model: {
                                  value: _vm.model.facematch,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "facematch", $$v)
                                  },
                                  expression: "model.facematch",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("facematch"),
                                      expression: "errors.has('facematch')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [_vm._v(_vm._s(_vm.errors.first("facematch")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "questionnaires.config.liveness",
                                  expression:
                                    "'questionnaires.config.liveness'",
                                },
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: {
                                    content: _vm.$t("liveness_description"),
                                    delay: { show: 300, hide: 500 },
                                    classes: ["tooltip-max-width"],
                                  },
                                  expression:
                                    "{content: $t('liveness_description'), delay: { show: 300, hide: 500 },classes: ['tooltip-max-width'],}",
                                  modifiers: { top: true },
                                },
                              ],
                            },
                            [
                              _c("label", { attrs: { for: "liveness" } }, [
                                _vm._v(_vm._s(_vm.$t("liveness"))),
                              ]),
                              _c("vs-switch", {
                                attrs: { id: "liveness" },
                                model: {
                                  value: _vm.model.liveness,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "liveness", $$v)
                                  },
                                  expression: "model.liveness",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("liveness"),
                                      expression: "errors.has('liveness')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [_vm._v(_vm._s(_vm.errors.first("liveness")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.auto",
                                  value: {
                                    content:
                                      "<div style='max-width: 200px'>Quantitativo de questões geradas aleatoriamente.</div>",
                                    delay: { show: 500, hide: 500 },
                                  },
                                  expression:
                                    "{\n                  content: `<div style='max-width: 200px'>Quantitativo de questões geradas aleatoriamente.</div>`,\n                  delay: { show: 500, hide: 500 }\n                }",
                                  modifiers: { auto: true },
                                },
                              ],
                              staticClass: "sm:col-span-1 content-start",
                            },
                            [
                              _c(
                                "vs-select",
                                {
                                  staticClass: "w-full",
                                  attrs: {
                                    type: "number",
                                    label: _vm.$t("tipo-questao-aleatoria"),
                                  },
                                  model: {
                                    value: _vm.model.random_questions.type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.model.random_questions,
                                        "type",
                                        $$v
                                      )
                                    },
                                    expression: "model.random_questions.type",
                                  },
                                },
                                [
                                  _c("vs-select-item", {
                                    attrs: { value: null, text: "Nenhum" },
                                  }),
                                  _c("vs-select-item", {
                                    attrs: { value: "basic", text: "Básico" },
                                  }),
                                  _c("vs-select-item", {
                                    attrs: {
                                      value: "score",
                                      text: "Pontuação",
                                    },
                                  }),
                                  _c("vs-select-item", {
                                    attrs: {
                                      value: "difficulty",
                                      text: "Dificuldade",
                                    },
                                  }),
                                  _c("vs-select-item", {
                                    attrs: { value: "theme", text: "Tema" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has(
                                        "random_questions.type"
                                      ),
                                      expression:
                                        "errors.has('random_questions.type')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first("random_questions.type")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm._.get(
                                      _vm.model.random_questions,
                                      "type",
                                      null
                                    ) !== null,
                                  expression:
                                    "_.get(model.random_questions, 'type', null) !== null",
                                },
                              ],
                              staticClass: "grid grid-cols-2",
                            },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["###"],
                                    expression: "['###']",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  label: _vm.$t("qtd-questoes-aleatorias"),
                                },
                                model: {
                                  value:
                                    _vm.model.random_questions.parameters
                                      .questions,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model.random_questions.parameters,
                                      "questions",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.random_questions.parameters.questions",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has(
                                        "random_questions.parameters.questions"
                                      ),
                                      expression:
                                        "errors.has('random_questions.parameters.questions')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        "random_questions.parameters.questions"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm.showActionOpenPopupRandomThemeConfig
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-items-center justify-center items-center",
                                    },
                                    [
                                      _c(
                                        "vs-button",
                                        {
                                          staticClass: "px-2 py-2 pt-1",
                                          attrs: {
                                            "text-color":
                                              "var(--content-color)",
                                            type: "flat",
                                          },
                                          on: {
                                            click:
                                              _vm.openPopupRandomQuestionsConfig,
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "cogs" },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "inline pl-1 font-semibold",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("action.config_theme")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "random_questions.themes"
                                              ),
                                              expression:
                                                "errors.has('random_questions.themes')",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                "random_questions.themes"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content:
                                      "Limita em qual tipo de ambiente pode ser feita a avaliação",
                                    delay: { show: 500, hide: 500 },
                                  },
                                  expression:
                                    "{\n                  content: 'Limita em qual tipo de ambiente pode ser feita a avaliação',\n                  delay: { show: 500, hide: 500 }\n                }",
                                },
                              ],
                              staticClass: "col-span-1 lg:row-start-auto",
                            },
                            [
                              _c("label", { staticClass: "mr-2 w-full" }, [
                                _vm._v(_vm._s(_vm.$t("dispositivo")) + ":"),
                              ]),
                              _c(
                                "vs-select",
                                {
                                  staticClass:
                                    "hover:text-primary font-semibold w-full",
                                  attrs: { multiple: "" },
                                  model: {
                                    value: _vm.model.env_restriction.device,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.model.env_restriction,
                                        "device",
                                        $$v
                                      )
                                    },
                                    expression: "model.env_restriction.device",
                                  },
                                },
                                [
                                  _c("vs-select-item", {
                                    attrs: {
                                      value: "desktop",
                                      text: "Aplicativo Desktop",
                                    },
                                  }),
                                  _c("vs-select-item", {
                                    attrs: { value: "web", text: "Navegador" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has(
                                        "env_restriction.device"
                                      ),
                                      expression:
                                        "errors.has('env_restriction.device')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.errors.first(
                                          "env_restriction.device"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.showOSSelect
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content:
                                          "Limita em quais sistemas operacionais pode ser feita a avaliação.",
                                        delay: { show: 500, hide: 500 },
                                      },
                                      expression:
                                        "{\n                  content: 'Limita em quais sistemas operacionais pode ser feita a avaliação.',\n                  delay: { show: 500, hide: 500 }\n                }",
                                    },
                                  ],
                                  staticClass: "col-span-1 lg:row-start-auto",
                                },
                                [
                                  _c("label", { staticClass: "mr-2 w-full" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("sistema-operacional"))
                                    ),
                                  ]),
                                  _c(
                                    "vs-select",
                                    {
                                      staticClass:
                                        "hover:text-primary font-semibold w-full",
                                      attrs: { multiple: "" },
                                      model: {
                                        value: _vm.model.env_restriction.os,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model.env_restriction,
                                            "os",
                                            $$v
                                          )
                                        },
                                        expression: "model.env_restriction.os",
                                      },
                                    },
                                    [
                                      _c("vs-select-item", {
                                        attrs: {
                                          value: "windows",
                                          text: "Windows",
                                        },
                                      }),
                                      _c("vs-select-item", {
                                        attrs: {
                                          value: "linux",
                                          text: "Linux",
                                        },
                                      }),
                                      _c("vs-select-item", {
                                        attrs: {
                                          value: "macos",
                                          text: "MacOS",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.errors.has(
                                              "env_restriction.os"
                                            ),
                                          expression:
                                            "errors.has('env_restriction.os')",
                                        },
                                      ],
                                      staticClass: "text-danger text-sm",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.errors.first(
                                              "env_restriction.os"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showBrowserSelect
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content: _vm.$t(
                                          "limita-em-quais-navegadores-pode-ser-feita"
                                        ),
                                        delay: { show: 500, hide: 500 },
                                      },
                                      expression:
                                        "{\n                  content: $t('limita-em-quais-navegadores-pode-ser-feita'),\n                  delay: { show: 500, hide: 500 }\n                }",
                                    },
                                  ],
                                  staticClass: "col-span-1 lg:row-start-auto",
                                },
                                [
                                  _c("label", { staticClass: "mr-2 w-full" }, [
                                    _vm._v(_vm._s(_vm.$t("navegador")) + ":"),
                                  ]),
                                  _c(
                                    "vs-select",
                                    {
                                      staticClass:
                                        "hover:text-primary font-semibold w-full",
                                      attrs: { multiple: "" },
                                      model: {
                                        value:
                                          _vm.model.env_restriction.browser,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model.env_restriction,
                                            "browser",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.env_restriction.browser",
                                      },
                                    },
                                    [
                                      _c("vs-select-item", {
                                        attrs: {
                                          value: "chrome",
                                          text: "Google Chrome",
                                        },
                                      }),
                                      _c("vs-select-item", {
                                        attrs: {
                                          value: "firefox",
                                          text: "Mozilla Firefox",
                                        },
                                      }),
                                      _c("vs-select-item", {
                                        attrs: {
                                          value: "opera",
                                          text: "Opera",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "env_restriction.browser"
                                          ),
                                          expression:
                                            "errors.has('env_restriction.browser')",
                                        },
                                      ],
                                      staticClass: "text-danger text-sm",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.errors.first(
                                              "env_restriction.browser"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: {
                                    content: _vm.$t(
                                      "navegador-modo-tela-chaia"
                                    ),
                                    delay: { show: 500, hide: 500 },
                                    classes: ["tooltip-max-width"],
                                  },
                                  expression:
                                    "{\n                  content: $t('navegador-modo-tela-chaia'),\n                  delay: { show: 500, hide: 500 },\n                  classes: ['tooltip-max-width']\n                }",
                                  modifiers: { top: true },
                                },
                              ],
                              staticClass: "col-span-1 lg:row-start-auto",
                            },
                            [
                              _c("label", { staticClass: "mr-2 w-full" }, [
                                _vm._v(_vm._s(_vm.$t("bloquear-tela"))),
                              ]),
                              _c(
                                "vs-select",
                                {
                                  staticClass:
                                    "hover:text-primary font-semibold w-full",
                                  model: {
                                    value: _vm.model.force_fullscreen,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "force_fullscreen",
                                        $$v
                                      )
                                    },
                                    expression: "model.force_fullscreen",
                                  },
                                },
                                [
                                  _c("vs-select-item", {
                                    attrs: {
                                      value: null,
                                      text: "Não Bloquear",
                                    },
                                  }),
                                  _c("vs-select-item", {
                                    attrs: {
                                      value: "lenient",
                                      text: "Leniente (30s)",
                                    },
                                  }),
                                  _c("vs-select-item", {
                                    attrs: {
                                      value: "moderate",
                                      text: "Moderada (15s)",
                                    },
                                  }),
                                  _c("vs-select-item", {
                                    attrs: {
                                      value: "severe",
                                      text: "Severa (Imediatamente)",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.errors.has("force_fullscreen") &&
                                        !_vm.isEmpty(
                                          _vm.model.force_fullscreen
                                        ),
                                      expression:
                                        "!errors.has('force_fullscreen') && !isEmpty(model.force_fullscreen)",
                                    },
                                  ],
                                  staticClass: "text-warning text-sm",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "navegadores-homologados-firefox-chrome-e"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("force_fullscreen"),
                                      expression:
                                        "errors.has('force_fullscreen')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.errors.first("force_fullscreen")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tour-config-3 col-span-1 lg:row-start-auto",
                            },
                            [
                              _c("vs-input", {
                                attrs: {
                                  type: "text",
                                  label: _vm.$t("senha-de-acesso"),
                                },
                                model: {
                                  value: _vm.model.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "password", $$v)
                                  },
                                  expression: "model.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "w-full" }, [
                      _c("div", { staticClass: "w-full pb-2" }, [
                        _c(
                          "label",
                          { staticClass: "block text-left font-medium" },
                          [_vm._v(_vm._s(_vm.$t("fields.term")))]
                        ),
                        _c("hr", { staticClass: "border-faint-grey" }),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid sm:grid-cols-2 grid-cols-1 md:grid-cols-4 gap-4",
                        },
                        [
                          _c("div", [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("data-hora-inicio"))),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("datetime", {
                                  staticClass:
                                    "flex-auto w-8/12 mr-1 tour-config-4",
                                  attrs: {
                                    "input-class":
                                      "vs-inputx vs-input--input normal",
                                    phrases: {
                                      ok: "Ok",
                                      cancel: _vm.$t("action.cancel"),
                                    },
                                    type: "date",
                                    format: "dd/MM/yyyy",
                                    "value-zone": "America/Sao_Paulo",
                                    auto: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.startChanged("date")
                                    },
                                  },
                                  model: {
                                    value: _vm.model.start_at.date,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model.start_at, "date", $$v)
                                    },
                                    expression: "model.start_at.date",
                                  },
                                }),
                                _c("datetime", {
                                  staticClass: "flex-auto w-4/12",
                                  attrs: {
                                    "input-class":
                                      "vs-inputx vs-input--input normal",
                                    phrases: {
                                      ok: "Ok",
                                      cancel: _vm.$t("action.cancel"),
                                    },
                                    type: "time",
                                    "minute-step": "5",
                                    format: "HH:mm",
                                    "value-zone": "America/Sao_Paulo",
                                    "use24-hour": "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.startChanged("time")
                                    },
                                  },
                                  model: {
                                    value: _vm.model.start_at.time,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model.start_at, "time", $$v)
                                    },
                                    expression: "model.start_at.time",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("start_at"),
                                    expression: "errors.has('start_at')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v(_vm._s(_vm.errors.first("start_at")))]
                            ),
                          ]),
                          _c("div", [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("data-hora-fim"))),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("datetime", {
                                  staticClass: "flex-auto w-8/12 mr-1",
                                  attrs: {
                                    "input-class":
                                      "vs-inputx vs-input--input normal",
                                    phrases: {
                                      ok: "Ok",
                                      cancel: _vm.$t("action.cancel"),
                                    },
                                    type: "date",
                                    format: "dd/MM/yyyy",
                                    "value-zone": "America/Sao_Paulo",
                                    auto: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.finishChanged("date")
                                    },
                                  },
                                  model: {
                                    value: _vm.model.finish_at.date,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model.finish_at, "date", $$v)
                                    },
                                    expression: "model.finish_at.date",
                                  },
                                }),
                                _c("datetime", {
                                  staticClass: "flex-auto w-4/12",
                                  attrs: {
                                    "input-class":
                                      "vs-inputx vs-input--input normal",
                                    phrases: {
                                      ok: "Ok",
                                      cancel: _vm.$t("action.cancel"),
                                    },
                                    type: "time",
                                    "minute-step": "5",
                                    format: "HH:mm",
                                    "value-zone": "America/Sao_Paulo",
                                    "use24-hour": "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.finishChanged("time")
                                    },
                                  },
                                  model: {
                                    value: _vm.model.finish_at.time,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model.finish_at, "time", $$v)
                                    },
                                    expression: "model.finish_at.time",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("finish_at"),
                                    expression: "errors.has('finish_at')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v(_vm._s(_vm.errors.first("finish_at")))]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "tour-config-2" },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["##########"],
                                    expression: "['##########']",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  label: _vm.$t("limite-tempo-min"),
                                },
                                model: {
                                  value: _vm.model.duration,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "duration", $$v)
                                  },
                                  expression: "model.duration",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("duration"),
                                      expression: "errors.has('duration')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [_vm._v(_vm._s(_vm.errors.first("duration")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.auto",
                                  value: {
                                    content:
                                      "<div style='max-width: 200px'>" +
                                      _vm.$t(
                                        "limite-de-tempo-por-questao-em-minutos"
                                      ) +
                                      "</div>",
                                    delay: { show: 500, hide: 500 },
                                  },
                                  expression:
                                    "{\n                  content: `<div style='max-width: 200px'>${$t('limite-de-tempo-por-questao-em-minutos')}</div>`,\n                  delay: { show: 500, hide: 500 }\n                }",
                                  modifiers: { auto: true },
                                },
                              ],
                              staticClass: "tour-config-2",
                            },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["###"],
                                    expression: "['###']",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  label: _vm.$t("tempo-questao-min"),
                                },
                                model: {
                                  value: _vm.model.question_duration,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "question_duration",
                                      $$v
                                    )
                                  },
                                  expression: "model.question_duration",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.errors.has("question_duration"),
                                      expression:
                                        "errors.has('question_duration')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first("question_duration")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.auto",
                                  value: {
                                    content:
                                      "<div style='max-width: 200px'>" +
                                      _vm.$t(
                                        "a-partir-de-quantos-minutos-apos-o-periodo"
                                      ) +
                                      "</div>",
                                    delay: { show: 500, hide: 500 },
                                  },
                                  expression:
                                    "{\n                  content: `<div style='max-width: 200px'>${$t('a-partir-de-quantos-minutos-apos-o-periodo')}</div>`,\n                  delay: { show: 500, hide: 500 }\n                }",
                                  modifiers: { auto: true },
                                },
                              ],
                            },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["###"],
                                    expression: "['###']",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  label: _vm.$t(
                                    "solicitar-justificativa-apos-min"
                                  ),
                                },
                                model: {
                                  value: _vm.model.entrance_justification_time,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "entrance_justification_time",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.entrance_justification_time",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has(
                                        "entrance_justification_time"
                                      ),
                                      expression:
                                        "errors.has('entrance_justification_time')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        "entrance_justification_time"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.auto",
                                  value: {
                                    content:
                                      "<div style='max-width: 200px'>" +
                                      _vm.$t(
                                        "tempo-maximo-para-iniciar-o-questionario"
                                      ) +
                                      "</div>",
                                    delay: { show: 500, hide: 500 },
                                  },
                                  expression:
                                    "{\n                  content: `<div style='max-width: 200px'>${$t('tempo-maximo-para-iniciar-o-questionario')}</div>`,\n                  delay: { show: 500, hide: 500 }\n                }",
                                  modifiers: { auto: true },
                                },
                              ],
                            },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["###"],
                                    expression: "['###']",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  label: _vm.$t("tempo-limite-de-entrada-min"),
                                },
                                model: {
                                  value: _vm.model.entrance_time_limit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "entrance_time_limit",
                                      $$v
                                    )
                                  },
                                  expression: "model.entrance_time_limit",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has(
                                        "entrance_time_limit"
                                      ),
                                      expression:
                                        "errors.has('entrance_time_limit')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first("entrance_time_limit")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.auto",
                                  value: {
                                    content:
                                      "<div style='max-width: 200px'>" +
                                      _vm.$t("limite-tempo-dias-desc") +
                                      "</div>",
                                    delay: { show: 500, hide: 500 },
                                  },
                                  expression:
                                    "{\n                  content: `<div style='max-width: 200px'>${$t('limite-tempo-dias-desc')}</div>`,\n                  delay: { show: 500, hide: 500 }}",
                                  modifiers: { auto: true },
                                },
                              ],
                            },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["###"],
                                    expression: "['###']",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  label: _vm.$t("limite-tempo-dias"),
                                },
                                model: {
                                  value: _vm.model.entrance_day_limit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "entrance_day_limit",
                                      $$v
                                    )
                                  },
                                  expression: "model.entrance_day_limit",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.errors.has("entrance_day_limit"),
                                      expression:
                                        "errors.has('entrance_day_limit')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first("entrance_day_limit")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-row pt-8" }),
                ]
              ),
              _c("div", { staticClass: "vx-row mt-4 mb-6 pb-4" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full justify-end items-center flex flex-row gap-2",
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "data_access.role.manage",
                            expression: "'data_access.role.manage'",
                          },
                        ],
                        staticClass:
                          "flex flex-row items-center hover:text-warning cursor-pointer font-normal gap-2",
                        on: {
                          click: function ($event) {
                            _vm.showDataAccess = true
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          attrs: {
                            icon: "EditIcon",
                            svgClasses: "h-5 w-5 font-normal",
                          },
                        }),
                        _c(
                          "label",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.top",
                                value: {
                                  content: _vm.$t(
                                    "adicionar-ou-remover-grupos-que-podem-alterar"
                                  ),
                                  delay: { show: 500, hide: 500 },
                                },
                                expression:
                                  "{\n                content: $t('adicionar-ou-remover-grupos-que-podem-alterar'),\n                delay: { show: 500, hide: 500 }\n              }",
                                modifiers: { top: true },
                              },
                            ],
                            staticClass: "cursor-pointer",
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("colaboracao")) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "vs-button",
                      {
                        attrs: { disabled: !_vm.validateForm },
                        on: { click: _vm.save },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.save")))]
                    ),
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "primary", type: "border" },
                        on: { click: _vm.cancel },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.back")))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: {
                title: _vm.$t("questoes"),
                icon: "feather icon-file-text",
              },
            },
            [
              _vm.content !== null && _vm.id !== null && _vm.isTabEnabled
                ? _c("EditorPanel", {
                    key: _vm.id,
                    ref: "editorPanel",
                    attrs: {
                      id: _vm.id,
                      content: _vm.content,
                      service: _vm.service,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: { title: _vm.$t("publicacao"), icon: "feather icon-send" },
            },
            [
              _vm.isTabEnabled
                ? _c("QuestionnairePublication", {
                    attrs: { content: _vm.content },
                    on: {
                      published: function ($event) {
                        return _vm._.set(_vm.content, "published_at", $event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: {
                title: _vm.$t("respostas"),
                icon: "feather icon-layers",
              },
            },
            [
              _vm.isTabEnabled
                ? _c("QuestionnaireAnswers", {
                    attrs: { id: _vm.id, content: _vm.content },
                    on: {
                      contentUpdated: function ($event) {
                        _vm.content = $event
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: { title: _vm.$t("resumo"), icon: "feather icon-grid" },
            },
            [
              _vm.isTabEnabled
                ? _c("QuestionnaireResume", {
                    attrs: {
                      id: _vm.id,
                      content_data: _vm.contentData,
                      averageScore: _vm.content.average_score,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("nova-disciplina"),
            fullscreen: "false",
            active: _vm.showDisciplineForm,
          },
          on: {
            "update:active": function ($event) {
              _vm.showDisciplineForm = $event
            },
          },
        },
        [
          _vm.showDisciplineForm
            ? _c("DisciplineForm", {
                attrs: { "z-index": "", inlineForm: "true" },
                on: {
                  created: function ($event) {
                    _vm.setDiscipline.apply(void 0, arguments)
                    _vm.showDisciplineForm = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("quem-pode-editar-esse-questionario"),
            active: _vm.showDataAccess,
          },
          on: {
            "update:active": function ($event) {
              _vm.showDataAccess = $event
            },
          },
        },
        [
          _vm.$acl.hasPermission("data_access.role.manage")
            ? _c("data-access-form", {
                attrs: { modelType: "questionnaires", modelId: _vm.id },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("selecione-a-disciplina"),
            active: _vm.showDisciplineGrid,
          },
          on: {
            "update:active": function ($event) {
              _vm.showDisciplineGrid = $event
            },
          },
        },
        [
          _vm.showDisciplineGrid
            ? _c("Grid", {
                staticClass: "disciplinePopupGrid",
                attrs: {
                  service: _vm.disciplineService,
                  route_name: "disciplines",
                  hide_actions: "true",
                  delegate: {
                    itemSelected: function () {
                      return _vm.handleDisciplineSelection
                    },
                  },
                  direction: "asc",
                  order_column: "title",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "gridThead",
                      fn: function () {
                        return [
                          _c(
                            "vs-th",
                            { key: "id", attrs: { "sort-key": "id" } },
                            [_vm._v("ID")]
                          ),
                          _c(
                            "vs-th",
                            { key: "title", attrs: { "sort-key": "title" } },
                            [_vm._v(_vm._s(_vm.$t("classes_title")))]
                          ),
                          _c(
                            "vs-th",
                            {
                              key: "course",
                              attrs: { "sort-key": "org_course.name" },
                            },
                            [_vm._v(_vm._s(_vm.$t("course")))]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "gridData",
                      fn: function (props) {
                        return _vm._l(props.data, function (tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr, attrs: { data: tr } },
                            [
                              _c(
                                "vs-td",
                                { key: "id", attrs: { width: "5%" } },
                                [
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "html-safe",
                                        rawName: "v-html-safe",
                                        value: tr.id,
                                        expression: "tr.id",
                                      },
                                    ],
                                  }),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { key: "title", attrs: { width: "65%" } },
                                [
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "html-safe",
                                        rawName: "v-html-safe",
                                        value: _vm.shortTitle(tr.title),
                                        expression: "shortTitle(tr.title)",
                                      },
                                    ],
                                  }),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { key: "course", attrs: { width: "30%" } },
                                [
                                  _vm._v(
                                    _vm._s(tr.course ? tr.course.name : "")
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  481606276
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("selecione-o-template"),
            active: _vm.showTemplateGrid,
          },
          on: {
            "update:active": function ($event) {
              _vm.showTemplateGrid = $event
            },
          },
        },
        [
          _vm.showTemplateGrid
            ? _c("Grid", {
                staticClass: "templatePopupGrid",
                attrs: {
                  service: _vm.contentQuestionnaireTemplateService,
                  route_name: "content_questionnaire_templates",
                  hide_actions: "true",
                  delegate: {
                    itemSelected: function () {
                      return _vm.handleTemplateSelection
                    },
                  },
                  direction: "asc",
                  order_column: "name",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "gridThead",
                      fn: function () {
                        return [
                          _c(
                            "vs-th",
                            { key: "id", attrs: { "sort-key": "id" } },
                            [_vm._v("ID")]
                          ),
                          _c(
                            "vs-th",
                            { key: "name", attrs: { "sort-key": "name" } },
                            [_vm._v("Nome")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "gridData",
                      fn: function (props) {
                        return _vm._l(props.data, function (tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr, attrs: { data: tr } },
                            [
                              _c(
                                "vs-td",
                                { key: "id", attrs: { width: "5%" } },
                                [_c("div", [_vm._v(_vm._s(tr.id))])]
                              ),
                              _c(
                                "vs-td",
                                { key: "name", attrs: { width: "95%" } },
                                [_c("div", [_vm._v(_vm._s(tr.name))])]
                              ),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  652359241
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("score-level-config"),
            active: _vm.showPopupScoreLevelConfig,
          },
          on: {
            "update:active": function ($event) {
              _vm.showPopupScoreLevelConfig = $event
            },
          },
        },
        [
          _vm._l(_vm.scoreLevelList, function (scoreLevel, index) {
            return _c(
              "div",
              { key: index },
              [
                _c("ScoreLevelConfig", {
                  attrs: {
                    model: scoreLevel,
                    index: index,
                    showButtonDelete: true,
                  },
                  on: { deleteScoreLevel: _vm.deleteScoreLevel },
                }),
              ],
              1
            )
          }),
          _c(
            "div",
            [
              _c(
                "div",
                { staticStyle: { "margin-left": "-10px" } },
                [
                  _c(
                    "vs-divider",
                    { staticClass: "mt-4", attrs: { position: "left" } },
                    [_vm._v(_vm._s(_vm.$t("adicionar")))]
                  ),
                ],
                1
              ),
              _c("ScoreLevelConfig", {
                attrs: { showIndex: false, showButtonAdd: true },
                on: { addScoreLevel: _vm.addScoreLevel },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          key: _vm.keyPopupRandomQuestionsConfig,
          attrs: {
            title: _vm.$t("random-question-theme-config"),
            active: _vm.showPopupRandomQuestionsConfig,
          },
          on: {
            "update:active": function ($event) {
              _vm.showPopupRandomQuestionsConfig = $event
            },
          },
        },
        [
          _vm._.get(_vm.model.random_questions, "themes", null) !== null &&
          _vm._.get(_vm.model.random_questions, "themes.length", 0) <= 0
            ? _c(
                "div",
                {
                  staticClass:
                    "w-full flex justify-items-center justify-center items-center",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("nenhum-tema-foi-encontrado")) + " "
                  ),
                ]
              )
            : _vm._e(),
          _vm._l(_vm.model.random_questions.themes, function (theme, index) {
            return _c("div", { key: "tIndex_" + index }, [
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2",
                },
                [
                  _c("div", { staticClass: "flex items-center w-2/6" }, [
                    _vm._v(" " + _vm._s(theme.name) + " "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex w-1/10" },
                    [
                      _c(
                        "vx-input-group",
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|numeric",
                                expression: "'required|numeric'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              lang: "pt-BR",
                              type: "number",
                              label: _vm.$t("weight"),
                            },
                            model: {
                              value: theme.weight,
                              callback: function ($$v) {
                                _vm.$set(theme, "weight", $$v)
                              },
                              expression: "theme.weight",
                            },
                          }),
                          _c("template", { slot: "append" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "append-text btn-addon flex items-end",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "input-group-text includeIcon includeIconOnly vs-component",
                                  },
                                  [_vm._v("%")]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ])
          }),
          _c(
            "div",
            { staticClass: "w-full flex gap-2 justify-end mt-4" },
            [
              _c(
                "vs-button",
                {
                  attrs: { type: "border" },
                  on: {
                    click: function ($event) {
                      _vm.showPopupRandomQuestionsConfig = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("ok")) + " ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }