import get from 'lodash-es/get'
import { ceil, floor, isArray, isEmpty, isString, sortBy } from 'lodash'

export default class AnswerRandomQuestionGenerator {

  questionnaire: any
  type: string
  quantity: number
  maxQuantity: number
  groups: Map<string, Array<any>>

  constructor(questionnaireModel: any){
    this.groups = new Map()
    this.quantity = 0
    this.maxQuantity = 0
    this.questionnaire = {}
    this.type = ''

    this.type = get(questionnaireModel, 'random_questions.type')
    this.quantity = get(questionnaireModel, 'random_questions.parameters.questions')

    this.loadQuestionnaire(questionnaireModel)

    if (!isEmpty(this.type)) {
      this.loadQuestionGroups(get(this.questionnaire, 'data.fields'), `data.${this.type}`)
    }
  }

  loadQuestionnaire(model) {
    let data = get(model, 'data')

    if (data && isString(data)) {
      try {
        data = JSON.parse(data)
        model.data = data
      } catch (error) {
        throw 'Erro ao tentar interpretar JSON do questionário.'
      }
    }

    this.questionnaire = model
  }

  loadQuestionGroups(fields, type) {
    if (fields && isArray(fields)) {
      const groups: Map<string, Array<any>> = new Map()

      this.maxQuantity = fields.length

      fields.forEach((question) => {

        // Let's ignore sections
        if (get(question, 'type') === 'section') return

        let typeVal = get(question, type)
        if (typeVal) typeVal = typeVal.toString()

        const group: Array<any> = groups.get(typeVal) as Array<any> || []
        group.push(question)
        groups.set(typeVal, group)
      })

      this.groups = groups
    }
  }

  groupRatios() {
    const selectionRatio = this.quantity / this.maxQuantity

    const groups = this.groups

    const keys = sortBy([...groups.keys()])

    const ratios: Map<string, number> = new Map()

    let taken = 0

    keys.forEach((key) => {
      const group = groups.get(key)
      if (group) {
        const groupTotal = group.length
        let takeFromGroup = groupTotal * selectionRatio

        takeFromGroup = (ceil(takeFromGroup) + taken) > this.quantity ? floor(takeFromGroup) : ceil(takeFromGroup)

        if (takeFromGroup + taken > this.quantity) return
        else {
          ratios.set(key, takeFromGroup)
          taken += takeFromGroup
        }
      }
    })

    return ratios
  }

}
