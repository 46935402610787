<template>
  <div>
    <vs-popup
      :title="$t('criacao-questionario')"
      :active.sync="questionnaireVideoIntroSteps"
    >
      <iframe class="ql-video ql-align-center" src="https://www.youtube.com/embed/crY8H2viA3w" width="100%" height="300"></iframe>
    </vs-popup>

    <form-wizard
      ref="formWizard"
      color="rgba(var(--vs-primary), 1)"
      :title="null"
      :subtitle="null"
      :hideButtons="true"
      step-size="xs"
      @on-change="tabChanged"
      :enableAllNav="this.content !== null">

      <tab-content :title="$t('formulario')" class="mb-5" icon="feather icon-home">
        <PlanUsageAlert target="content_questionnaire" v-if="id === null"/>
        <vx-tour
          name="questionnaireIntroSteps"
          :auto="false"
          :delegate="ckeckVideoClosed"
        />

        <vx-card :title="$t('dados-basicos')">

          <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-2">
              <vs-input
                name="model.name"
                id="model_name"
                class="w-full required"
                :label="$t('nome-do-formulario')"
                v-model="model.name"
                v-validate:name="'required|max:191'"
              />
              <span class="text-danger text-sm" v-show="errors.has('name')">{{
                errors.first('name')
              }}</span>
            </div>
          </div>

          <div class="vx-row mt-2">
            <div class="vx-col sm:w-1/1 w-full mb-2">
              <label id="description">{{ $t('informacoes-gerais') }}</label>
              <ComplexEditor
                :questionnaire_id.sync="contentId"
                v-bind:editor_data.sync="editorData"
                :placeholder="$t('digite-aqui')"
              ></ComplexEditor>
              <span
                class="text-danger text-sm"
                v-show="errors.has('description')"
                >{{ errors.first('description') }}</span
              >
            </div>
          </div>

          <div class="vx-row" v-if="$acl.hasPermission('disciplines.show')">
            <div class="vx-col sm:w-1/1 w-full mb-2">
              <vx-input-group>
                <vs-input
                  disabled
                  :value="disciplineName"
                  :label="$t('discipline')"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('discipline_id')"
                  >{{ errors.first('discipline_id') }}</span
                >

                <template slot="append">
                  <div class="append-text btn-addon flex items-end">
                    <vs-button
                     v-permission="'questionnaires.discipline.search'"
                      color="primary"
                      type="border"
                      icon="search"
                      :title="$t('buscar')"
                      @click="showDisciplineGrid = true"
                      v-tooltip.top="{
                        content: 'Pesquisar disciplina',
                        delay: { show: 1000, hide: 100 }
                      }"
                    ></vs-button>
                    <vs-button
                      v-permission="'questionnaires.discipline.create'"
                      color="primary"
                      type="border"
                      icon="add"
                      :title="$t('incluir')"
                      @click="showDisciplineForm = true"
                      v-tooltip.top="{
                        content: 'Criar nova disciplina',
                        delay: { show: 1000, hide: 100 }
                      }"
                    ></vs-button>
                    <vs-button
                      v-permission="'questionnaires.discipline.clear'"
                      color="primary"
                      type="border"
                      icon="clear"
                      :title="$t('action.clear')"
                      @click="setDiscipline(undefined, '')"
                      v-tooltip.top="{
                        content: 'Limpar campo disciplina',
                        delay: { show: 1000, hide: 100 }
                      }"
                    ></vs-button>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>

           <div class="vx-row" v-if="$acl.hasPermission('content_questionnaire_templates.show')">
            <div class="vx-col sm:w-1/1 w-full mb-2">
              <vx-input-group>
                <vs-input
                  disabled
                  :value="templateName"
                  label="Template"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('template_id')"
                  >{{ errors.first('template_id') }}</span
                >

                <template slot="append">
                  <div class="append-text btn-addon flex items-end">
                    <vs-button
                     v-permission="'content_questionnaire_templates.grid'"
                      color="primary"
                      type="border"
                      icon="search"
                      :title="$t('buscar')"
                      @click="showTemplateGrid = true"
                      v-tooltip.top="{
                        content: 'Pesquisar template',
                        delay: { show: 1000, hide: 100 }
                      }"
                    ></vs-button>
                    <vs-button
                      v-permission="'content_questionnaire_templates.grid'"
                      color="primary"
                      type="border"
                      icon="clear"
                      :title="$t('limpar')"
                      @click="setTemplate(undefined, '')"
                      v-tooltip.top="{
                        content: 'Limpar template',
                        delay: { show: 1000, hide: 100 }
                      }"
                    ></vs-button>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>

        </vx-card>
        <vx-card class="mt-2 mb-3 config" :title="$t('configuracao')" collapseAction :isContentCollapsedProp="configCollapsed">
          <div class="grid grid-cols-1 gap-y-8">
            <div class="w-full">
              <div class="w-full pb-2"> <label class="block text-left font-medium">{{ $t('apresentacao') }}</label><hr class="border-faint-grey"></div>
              <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div>
                  <label for>{{ $t('uma-questao-por-vez') }}</label>
                  <vs-switch
                  v-model="model.single_question"
                  @input="changedPerQuestion($event)"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('single_question')">{{
                    errors.first('single_question')
                  }}</span>
                </div>
                <div>
                  <label for>{{ $t('nao-voltar') }}</label>
                  <vs-switch
                  v-model="model.disallow_question_return"
                  @input="atLeast($event, 'should_have_at_least_one_grouping', 'single_question', 'single_section')"
                  v-tooltip.auto="{
                    content: 'Quando o questionário for apresentado em uma questão ou uma sessão por vez, o botão de voltar estará desabilitado.',
                    delay: { show: 500, hide: 500 }
                  }"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('disallow_question_return')"
                    >{{ errors.first('disallow_question_return') }}</span
                  >
                </div>
                <div>
                  <label for>{{ $t('uma-secao-por-vez') }}</label>
                  <vs-switch
                  v-model="model.single_section"
                  @input="changedPerSection($event)"
                  />
                </div>
                <div>
                  <label for>{{ $t('controle-por-voz') }}</label>
                  <vs-switch
                    v-tooltip.auto="{
                      content: 'Permite o usuário realizar o questionário utilizando somente a voz.',
                      delay: { show: 500, hide: 500 }
                    }"
                    v-model="model.voice_control"
                  />
                </div>
                <div>
                  <label for>{{ $t('progressive-questionnaire') }}</label>
                  <vs-switch
                    v-tooltip.auto="{
                      content: 'Permite o usuário realizar o questionário utilizando somente a voz.',
                      delay: { show: 500, hide: 500 }
                    }"
                    v-model="model.progressive_questionnaire"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('progressive_questionnaire_return')"
                    >{{ errors.first('progressive_questionnaire_return') }}</span
                  >
                </div>
                <div class="tour-config-1 lg:row-start-auto" v-if="model.progressive_questionnaire">
                  <vs-input
                  type="number"
                  :label="$t('progressive-questionnaire-count')"
                  v-model="model.progressive_questionnaire_count"
                  v-mask="['##########']"
                  />
                </div>
              </div>
            </div>

            <div class="w-full">
              <div class="w-full pb-2"> <label class="block text-left font-medium">{{ $t('resultado') }}</label><hr class="border-faint-grey"></div>
              <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
                <div
                  v-tooltip.top="{
                  content: `<div style='max-width: 200px'>Permite o participante visualizar as questões, notas e feedback das questões logo após finalizar o questionário. Quando esta opção esta desmarcada, ele só poderá visualizar estes dados após a liberação das respostas pelo professor.</div>`,
                  delay: { show: 1000, hide: 100 }
                  }"
                  >
                  <label for>{{ $t('previa-resultado') }}</label>
                  <vs-switch v-model="model.view_results" />
                </div>

                <div
                  class="tour-config-5"
                  v-tooltip.top="{
                  content: `<div style='max-width: 200px'>Permite o participante visualizar a resposta padrão, caso esteja cadastrada na questão.</div>`,
                  delay: { show: 1000, hide: 100 }
                  }"
                >
                  <label for>{{ $t('visualizar-gabarito') }}</label>
                  <vs-switch v-model="model.view_answers" />
                </div>
                <div
                  v-tooltip.top="{
                  content: `<div style='max-width: 200px'>Permite o participante visualizar sua posição ou ranking em relação aos outros participantes.</div>`,
                  delay: { show: 1000, hide: 100 }
                  }"
                >
                  <label for>{{ $t('visualizar-colocacao') }}</label>
                  <vs-switch v-model="model.view_rank" />
                </div>
                <div
                  v-tooltip.top="{
                  content: `<div style='max-width: 200px'>Não apresenta as questões no resultado. Usar esta opção quando não desejar que visualizem o gabarito ou feedback antes de todos finalizarem.</div>`,
                  delay: { show: 1000, hide: 100 }
                  }"
                >
                  <label for>{{ $t('nao-apresentar-questoes') }}</label>
                  <vs-switch v-model="model.hide_questions" />
                </div>
                <div class="tour-config-1 lg:row-start-auto">
                  <vs-input
                  type="number"
                  :label="$t('media-aprovacao')"
                  v-model="model.average_score"
                  v-mask="['##########']"
                  />
                </div>
                <div class="tour-config-x3 lg:row-start-auto"
                  v-tooltip.auto="{
                    content: `<div style='max-width: 200px'>Pontuação padrão para cada questão. Se essa pontuação for configurada, todas as pontuações das questões serão substituídas por esse valor.</div>`,
                    delay: { show: 500, hide: 500 }
                  }"
                  >
                  <vs-input
                  type="number"
                  :label="$t('score-default')"
                  v-model="model.question_score"/>
                </div>
                <div class="lg:row-start-auto col-span-3" v-permission="'questionnaires.config.score_level'">
                  <label for>{{ $t('score-level') }}</label>
                  <vs-switch v-model="showScoreLevelConfig"/>
                  <div v-if="showScoreLevelConfig" class="pt-3">
                    <vs-button text-color="var(--content-color)" type="flat" @click="showPopupScoreLevelConfig=true" class="px-2 py-2 pt-1">
                      <font-awesome-icon icon="cogs" />
                      <div class="inline pl-1 font-semibold">{{ $t('action.config') }}</div>
                    </vs-button>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full">
              <div class="w-full pb-2"> <label class="block text-left font-medium">{{ $t('seguranca') }}</label><hr class="border-faint-grey"></div>
              <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label for>{{ $t('embaralhar-questoes') }}</label>
                  <vs-switch v-model="model.shuffle_questions" />
                </div>

                <div>
                  <label for>{{ $t('continuar-posteriormente') }}</label>
                  <vs-switch v-model="model.leave" @input="onlyOne($event, 'leave', 'multiple_attempts')" />
                  <span class="text-danger text-sm" v-show="errors.has('leave')">{{
                    errors.first('leave')
                  }}</span>
                </div>

                <div>
                  <label for>{{ $t('multiplas-tentativas') }}</label>
                  <vs-switch v-model="model.multiple_attempts" @input="onlyOne($event, 'multiple_attempts', 'leave')" />
                </div>

                <div>
                  <label for>{{ $t('esconder-valor-da-questao') }}</label>
                  <vs-switch v-model="model.hide_question_score"/>
                </div>

                <div class="sm:col-span-1 content-start">
                  <label for>{{ $t('somente-convidados') }}</label>
                  <vs-switch
                    v-tooltip.top="{
                      content: 'Somente usuários adicionados na lista de emails da publicação poderão responder esta avaliação.',
                      delay: { show: 500, hide: 500 },
                    }"
                    v-model="model.restricted_publication"
                  />
                </div>

                <div class="sm:col-span-1 content-start">
                  <label for>{{ $t('permitir-pausas') }}</label>
                  <vs-switch
                    @input="enforceCanContinue"
                    v-tooltip.top="{
                      classes: ['tooltip-max-width'],
                      content: 'Permitir que o usuário pause a avaliação com uma justificativa. A pausa não causará pausa no tempo da avaliação se estiver configurado e deverá ser justificada.',
                      delay: { show: 500, hide: 500 },
                    }"
                    v-model="model.can_pause"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('can_pause')">{{
                    errors.first('can_pause')
                  }}</span>
                </div>
                <div
                 v-tooltip.top="{content: $t('second_chance_description'), delay: { show: 300, hide: 500 },classes: ['tooltip-max-width'],}"
                  >
                  <label for="second_chance">{{ $t('second_chance') }}</label>
                  <vs-switch
                    id="second_chance"
                    v-model="secondChance" />
                  <span class="text-danger text-sm" v-show="errors.has('second_chance')">{{errors.first('second_chance')}}</span>
                </div>
                <div v-show="secondChance" class="">
                  <vs-input
                    type="number"
                    :label="$t('second_chance_total')"
                    v-model="second_chance.parameters.total"
                    v-mask="['###']"></vs-input>
                  <span class="text-danger text-sm" v-show="errors.has('second_chance.parameters.total')">{{errors.first('second_chance.parameters.total')}}</span>
                </div>
                <div v-show="secondChance" class="">
                    <vs-input
                    type="number"
                    :label="$t('second_chance_min_score')"
                    v-model="second_chance.parameters.min_score"
                    v-mask="['###']"></vs-input>
                  <span class="text-danger text-sm" v-show="errors.has('second_chance.parameters.min_score')">{{errors.first('second_chance.parameters.min_score')}}</span>
                </div>

                <div v-permission="'questionnaires.config.facematch'"
                    v-tooltip.top="{content: $t('facematch_description'), delay: { show: 300, hide: 500 },classes: ['tooltip-max-width'],}"
                  >
                  <label for="facematch">{{ $t('facematch') }}</label>
                  <vs-switch
                    id="facematch"
                    v-model="model.facematch" />
                  <span class="text-danger text-sm" v-show="errors.has('facematch')">{{errors.first('facematch')}}</span>
                </div>

                <div v-permission="'questionnaires.config.liveness'"
                      v-tooltip.top="{content: $t('liveness_description'), delay: { show: 300, hide: 500 },classes: ['tooltip-max-width'],}"
                  >
                  <label for="liveness">{{ $t('liveness') }}</label>
                  <vs-switch
                    id="liveness"
                    v-model="model.liveness"
                    />
                  <span class="text-danger text-sm" v-show="errors.has('liveness')">{{errors.first('liveness')}}</span>
                </div>

                <div
                  class="sm:col-span-1 content-start"
                  v-tooltip.auto="{
                    content: `<div style='max-width: 200px'>Quantitativo de questões geradas aleatoriamente.</div>`,
                    delay: { show: 500, hide: 500 }
                  }"
                >
                  <vs-select
                    class="w-full"
                    type="number"
                    :label="$t('tipo-questao-aleatoria')"
                    v-model="model.random_questions.type">
                    <vs-select-item
                      :value="null"
                      text="Nenhum"
                    />
                    <vs-select-item
                      :value="'basic'"
                      text="Básico"
                    />
                    <vs-select-item
                      :value="'score'"
                      text="Pontuação"
                    />
                    <vs-select-item
                      :value="'difficulty'"
                      text="Dificuldade"
                    />
                    <vs-select-item
                      :value="'theme'"
                      text="Tema"
                    />
                  </vs-select>
                  <span class="text-danger text-sm" v-show="errors.has('random_questions.type')">{{
                    errors.first('random_questions.type')
                  }}</span>
                </div>

                <div v-show="_.get(model.random_questions, 'type', null) !== null" class="grid grid-cols-2">
                  <vs-input
                    type="number"
                    :label="$t('qtd-questoes-aleatorias')"
                    v-model="model.random_questions.parameters.questions"
                    v-mask="['###']"
                  >
                  </vs-input>
                  <span class="text-danger text-sm" v-show="errors.has('random_questions.parameters.questions')">{{
                    errors.first('random_questions.parameters.questions')
                  }}</span>
                  <div v-if="showActionOpenPopupRandomThemeConfig" class="flex justify-items-center justify-center items-center">
                    <vs-button text-color="var(--content-color)" type="flat" @click="openPopupRandomQuestionsConfig" class="px-2 py-2 pt-1">
                      <font-awesome-icon icon="cogs" />
                      <div class="inline pl-1 font-semibold">{{ $t('action.config_theme') }}</div>
                    </vs-button>
                    <span class="text-danger text-sm" v-show="errors.has('random_questions.themes')">{{
                      errors.first('random_questions.themes')
                    }}</span>
                  </div>
                </div>
                <div
                  class="col-span-1 lg:row-start-auto"
                  v-tooltip="{
                    content: 'Limita em qual tipo de ambiente pode ser feita a avaliação',
                    delay: { show: 500, hide: 500 }
                  }"
                >
                  <label class="mr-2 w-full">{{ $t('dispositivo') }}:</label>
                  <vs-select
                    multiple
                    v-model="model.env_restriction.device"
                    class="hover:text-primary font-semibold w-full"
                  >
                    <vs-select-item
                    value="desktop"
                    text="Aplicativo Desktop" />

                    <vs-select-item
                    value="web"
                    text="Navegador" />

                  </vs-select>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('env_restriction.device')"
                  >
                    {{ errors.first('env_restriction.device') }}
                  </span>
                </div>

                <div
                  v-if="showOSSelect"
                  class="col-span-1 lg:row-start-auto"
                  v-tooltip="{
                    content: 'Limita em quais sistemas operacionais pode ser feita a avaliação.',
                    delay: { show: 500, hide: 500 }
                  }"
                >
                  <label class="mr-2 w-full">{{ $t('sistema-operacional') }}</label>
                  <vs-select
                    multiple
                    v-model="model.env_restriction.os"
                    class="hover:text-primary font-semibold w-full"
                  >
                    <vs-select-item
                    :value="'windows'"
                    text="Windows" />

                    <vs-select-item
                    :value="'linux'"
                    text="Linux" />

                    <vs-select-item
                    :value="'macos'"
                    text="MacOS" />

                  </vs-select>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('env_restriction.os')"
                  >
                    {{ errors.first('env_restriction.os') }}
                  </span>
                </div>

                <div
                  v-if="showBrowserSelect"
                  class="col-span-1 lg:row-start-auto"
                  v-tooltip="{
                    content: $t('limita-em-quais-navegadores-pode-ser-feita'),
                    delay: { show: 500, hide: 500 }
                  }"
                >
                  <label class="mr-2 w-full">{{ $t('navegador') }}:</label>
                  <vs-select
                    multiple
                    v-model="model.env_restriction.browser"
                    class="hover:text-primary font-semibold w-full"
                  >

                    <vs-select-item
                    :value="'chrome'"
                    text="Google Chrome" />

                    <vs-select-item
                    :value="'firefox'"
                    text="Mozilla Firefox" />

                    <vs-select-item
                    :value="'opera'"
                    text="Opera" />

                  </vs-select>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('env_restriction.browser')"
                  >
                    {{ errors.first('env_restriction.browser') }}
                  </span>
                </div>

                <div
                  class="col-span-1 lg:row-start-auto"
                  v-tooltip.top="{
                    content: $t('navegador-modo-tela-chaia'),
                    delay: { show: 500, hide: 500 },
                    classes: ['tooltip-max-width']
                  }"
                >
                  <label class="mr-2 w-full">{{ $t('bloquear-tela') }}</label>
                  <vs-select v-model="model.force_fullscreen" class="hover:text-primary font-semibold w-full">

                    <vs-select-item
                    :value="null"
                    text="Não Bloquear" />

                    <vs-select-item
                    value="lenient"
                    text="Leniente (30s)" />

                    <vs-select-item
                    value="moderate"
                    text="Moderada (15s)" />

                    <vs-select-item
                    value="severe"
                    text="Severa (Imediatamente)" />

                  </vs-select>
                  <span
                    class="text-warning text-sm"
                    v-show="!errors.has('force_fullscreen') && !isEmpty(model.force_fullscreen)"
                  >
                    {{ $t('navegadores-homologados-firefox-chrome-e') }}
                  </span>
                  <span
                      class="text-danger text-sm"
                      v-show="errors.has('force_fullscreen')"
                    >
                      {{ errors.first('force_fullscreen') }}
                    </span>
                </div>
                <div class="tour-config-3 col-span-1 lg:row-start-auto">
                  <vs-input
                  type="text"
                  :label="$t('senha-de-acesso')"
                  v-model="model.password"
                  />
                </div>
              </div>
            </div>

            <div class="w-full">
              <div class="w-full pb-2"> <label class="block text-left font-medium">{{ $t('fields.term') }}</label><hr class="border-faint-grey"></div>
              <div class="grid sm:grid-cols-2 grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                  <label>{{ $t('data-hora-inicio') }}</label>
                  <div class="flex">
                    <datetime
                        v-model="model.start_at.date"
                        @input="startChanged('date')"
                        input-class="vs-inputx vs-input--input normal"
                        :phrases="{ok: 'Ok', cancel: $t('action.cancel')}"
                        type="date"
                        format="dd/MM/yyyy"
                        value-zone="America/Sao_Paulo"
                        class="flex-auto w-8/12 mr-1 tour-config-4"
                        auto
                    >
                    </datetime>
                    <datetime
                        v-model="model.start_at.time"
                        @input="startChanged('time')"
                        input-class="vs-inputx vs-input--input normal"
                        :phrases="{ok: 'Ok', cancel: $t('action.cancel')}"
                        type="time"
                        minute-step="5"
                        format="HH:mm"
                        value-zone="America/Sao_Paulo"
                        use24-hour
                        class="flex-auto w-4/12"
                    >
                    </datetime>
                  </div>
                  <span
                  class="text-danger text-sm"
                  v-show="errors.has('start_at')"
                  >{{ errors.first('start_at') }}</span
                  >
                </div>

                <div>
                  <label>{{ $t('data-hora-fim') }}</label>
                  <div class="flex">
                  <datetime
                      v-model="model.finish_at.date"
                      input-class="vs-inputx vs-input--input normal"
                      :phrases="{ok: 'Ok', cancel: $t('action.cancel')}"
                      @input="finishChanged('date')"
                      type="date"
                      format="dd/MM/yyyy"
                      value-zone="America/Sao_Paulo"
                      class="flex-auto w-8/12 mr-1"
                      auto
                  >
                  </datetime>
                  <datetime
                      v-model="model.finish_at.time"
                      input-class="vs-inputx vs-input--input normal"
                      :phrases="{ok: 'Ok', cancel: $t('action.cancel')}"
                      @input="finishChanged('time')"
                      type="time"
                      minute-step="5"
                      format="HH:mm"
                      value-zone="America/Sao_Paulo"
                      use24-hour
                      class="flex-auto w-4/12"
                  >
                  </datetime>
                  </div>
                  <span
                  class="text-danger text-sm"
                  v-show="errors.has('finish_at')"
                  >{{ errors.first('finish_at') }}</span
                  >
                </div>

                <div class="tour-config-2">
                  <vs-input
                  type="number"
                  :label="$t('limite-tempo-min')"
                  v-model="model.duration"
                  v-mask="['##########']"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('duration')">{{
                    errors.first('duration')
                  }}</span>
                </div>

                <div class="tour-config-2"
                  v-tooltip.auto="{
                    content: `<div style='max-width: 200px'>${$t('limite-de-tempo-por-questao-em-minutos')}</div>`,
                    delay: { show: 500, hide: 500 }
                  }"
                >
                  <vs-input
                    type="number"
                    :label="$t('tempo-questao-min')"
                    v-model="model.question_duration"
                    v-mask="['###']"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('question_duration')">{{
                    errors.first('question_duration')
                  }}</span>
                </div>

                <div
                  v-tooltip.auto="{
                    content: `<div style='max-width: 200px'>${$t('a-partir-de-quantos-minutos-apos-o-periodo')}</div>`,
                    delay: { show: 500, hide: 500 }
                  }"
                >
                  <vs-input
                    type="number"
                    :label="$t('solicitar-justificativa-apos-min')"
                    v-model="model.entrance_justification_time"
                    v-mask="['###']"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('entrance_justification_time')">{{
                    errors.first('entrance_justification_time')
                  }}</span>
                </div>

                <div
                  v-tooltip.auto="{
                    content: `<div style='max-width: 200px'>${$t('tempo-maximo-para-iniciar-o-questionario')}</div>`,
                    delay: { show: 500, hide: 500 }
                  }"
                >
                  <vs-input
                    type="number"
                    :label="$t('tempo-limite-de-entrada-min')"
                    v-model="model.entrance_time_limit"
                    v-mask="['###']"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('entrance_time_limit')">{{
                    errors.first('entrance_time_limit')
                  }}</span>
                </div>
                <div
                  v-tooltip.auto="{
                    content: `<div style='max-width: 200px'>${$t('limite-tempo-dias-desc')}</div>`,
                    delay: { show: 500, hide: 500 }}">
                  <vs-input
                    type="number"
                    :label="$t('limite-tempo-dias')"
                    v-model="model.entrance_day_limit"
                    v-mask="['###']"/>
                  <span class="text-danger text-sm" v-show="errors.has('entrance_day_limit')">{{errors.first('entrance_day_limit')}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-row pt-8">

          </div>
        </vx-card>

        <div class="vx-row mt-4 mb-6 pb-4">
          <div class="w-full justify-end items-center flex flex-row gap-2">
            <div
              class="flex flex-row items-center hover:text-warning cursor-pointer font-normal gap-2"
              v-permission="'data_access.role.manage'"
              @click="showDataAccess = true"
            >
              <feather-icon icon="EditIcon" svgClasses="h-5 w-5 font-normal"/>
              <label
                class="cursor-pointer"

                v-tooltip.top="{
                  content: $t('adicionar-ou-remover-grupos-que-podem-alterar'),
                  delay: { show: 500, hide: 500 }
                }"
              >
                {{ $t('colaboracao') }}
              </label>
            </div>
            <vs-button
              :disabled="!validateForm"
              @click="save"
              >{{ $t('action.save') }}</vs-button
            >
            <vs-button
              color="primary"
              type="border"
              @click="cancel"
              >{{ $t('action.back') }}</vs-button
            >
          </div>
        </div>
      </tab-content>

      <tab-content :title="$t('questoes')" class="mb-5" icon="feather icon-file-text">
        <EditorPanel
          :id="id"
          :content="content"
          :service="service"
          v-if="content !== null && id !== null && isTabEnabled"
          :key="id"
          ref="editorPanel"
        ></EditorPanel>
      </tab-content>

      <tab-content :title="$t('publicacao')" class="mb-5" icon="feather icon-send">
        <QuestionnairePublication
          :content="content"
          @published="_.set(content, 'published_at', $event)"
          v-if="isTabEnabled"
        ></QuestionnairePublication>
      </tab-content>

      <tab-content :title="$t('respostas')" class="mb-5" icon="feather icon-layers">
        <QuestionnaireAnswers
          :id="id"
          :content="content"
          @contentUpdated="content = $event"
          v-if="isTabEnabled"
        ></QuestionnaireAnswers>
      </tab-content>

      <tab-content :title="$t('resumo')" class="mb-5" icon="feather icon-grid">
        <QuestionnaireResume
          :id="id"
          :content_data="contentData"
          :averageScore="content.average_score"
          v-if="isTabEnabled"
        ></QuestionnaireResume>
      </tab-content>
    </form-wizard>

    <vs-popup
      :title="$t('nova-disciplina')"
      fullscreen="false"
      :active.sync="showDisciplineForm"
    >
      <DisciplineForm
        z-index
        v-if="showDisciplineForm"
        inlineForm="true"
        @created="
          setDiscipline(...arguments)
          showDisciplineForm = false
        "
      ></DisciplineForm>
    </vs-popup>

    <vs-popup :title="$t('quem-pode-editar-esse-questionario')" :active.sync="showDataAccess">
      <data-access-form v-if="$acl.hasPermission('data_access.role.manage')" modelType="questionnaires" :modelId="id"/>
    </vs-popup>

    <vs-popup :title="$t('selecione-a-disciplina')" :active.sync="showDisciplineGrid">
      <Grid
        class="disciplinePopupGrid"
        :service="disciplineService"
        route_name="disciplines"
        hide_actions="true"
        :delegate="{itemSelected: () => handleDisciplineSelection} "
        v-if="showDisciplineGrid"
        direction="asc"
        order_column="title"
      >
        <template v-slot:gridThead>
          <vs-th key="id" sort-key="id">ID</vs-th>
          <vs-th key="title" sort-key="title">{{ $t('classes_title') }}</vs-th>
          <vs-th key="course" sort-key="org_course.name">{{ $t('course') }}</vs-th>
        </template>
        <template v-slot:gridData="props">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
            <vs-td width="5%" key="id">
              <div v-html-safe="tr.id"></div>
            </vs-td>
            <vs-td width="65%" key="title">
              <div v-html-safe="shortTitle(tr.title)"></div>
            </vs-td>
            <vs-td width="30%" key="course">{{
              tr.course ? tr.course.name : ''
            }}</vs-td>
          </vs-tr>
        </template>
      </Grid>
    </vs-popup>

    <vs-popup :title="$t('selecione-o-template')" :active.sync="showTemplateGrid">
      <Grid
        class="templatePopupGrid"
        :service="contentQuestionnaireTemplateService"
        route_name="content_questionnaire_templates"
        hide_actions="true"
        :delegate="{itemSelected: () => handleTemplateSelection} "
        v-if="showTemplateGrid"
        direction="asc"
        order_column="name"
      >
        <template v-slot:gridThead>
          <vs-th key="id" sort-key="id">ID</vs-th>
          <vs-th key="name" sort-key="name">Nome</vs-th>
        </template>
        <template v-slot:gridData="props">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
            <vs-td width="5%" key="id">
              <div>{{tr.id}}</div>
            </vs-td>
            <vs-td width="95%" key="name">
              <div>{{tr.name}}</div>
            </vs-td>
          </vs-tr>
        </template>
      </Grid>
    </vs-popup>
    <vs-popup :title="$t('score-level-config')" :active.sync="showPopupScoreLevelConfig">
      <div v-for="(scoreLevel, index) in scoreLevelList" :key="index">
        <ScoreLevelConfig :model="scoreLevel"
                            :index="index"
                            :showButtonDelete="true"
                            @deleteScoreLevel="deleteScoreLevel">
        </ScoreLevelConfig>
      </div>
      <div>
      <div style="margin-left: -10px;">
        <vs-divider class="mt-4" position="left">{{ $t('adicionar') }}</vs-divider>
      </div>
      <ScoreLevelConfig :showIndex="false"
                        :showButtonAdd="true"
                        @addScoreLevel="addScoreLevel">
      </ScoreLevelConfig>
    </div>

    </vs-popup>

    <vs-popup :title="$t('random-question-theme-config')" :active.sync="showPopupRandomQuestionsConfig" :key="keyPopupRandomQuestionsConfig">
      <div class="w-full flex justify-items-center justify-center items-center" v-if="_.get(model.random_questions, 'themes', null) !== null && _.get(model.random_questions, 'themes.length', 0) <= 0">
          {{$t('nenhum-tema-foi-encontrado')}}
      </div>
      <div v-for="(theme, index) in  model.random_questions.themes" :key="`tIndex_${index}`">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
          <div class="flex items-center w-2/6">
            {{theme.name}}
          </div>
          <div class="flex w-1/10">
            <vx-input-group>
              <vs-input
                lang="pt-BR"
                type="number"
                class="required"
                v-validate="'required|numeric'"
                :label="$t('weight')"
                v-model="theme.weight"/>
                <template slot="append">
                    <div class="append-text btn-addon flex items-end">
                        <span class="input-group-text includeIcon includeIconOnly vs-component">%</span>
                      </div>
                  </template>
            </vx-input-group>
          </div>
        </div>
      </div>
      <div class="w-full flex gap-2 justify-end mt-4">
        <vs-button
          type="border"
          @click="showPopupRandomQuestionsConfig = false">{{ $t('ok') }}
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
// import draggable from 'vuedraggable'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { Settings } from 'luxon'

import FormWizard from '@/components/form-wizard/FormWizard'
import TabContent from '@/components/form-wizard/TabContent'

import EditorPanel from './elements/EditorPanel'
import QuestionnaireAnswers from './elements/QuestionnaireAnswers'
import QuestionnaireResume from './elements/QuestionnaireResume'
import QuestionnairePublication from './elements/QuestionnairePublication'
import ComplexEditor from './elements/ComplexEditor'
import DisciplineForm from '../disciplines/Form'
import PlanUsageAlert from '@/components/finance/PlanUsageAlert'
import ScoreLevelConfig from './ScoreLevelConfig.vue'

import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import DisciplineService from '@/services/api/DisciplineService'
import ContentQuestionnaireTemplateService from '@/services/api/ContentQuestionnaireTemplateService'

// import HelpIcon from '@/components/HelpIcon'
import DataAccessForm from '@/components/DataAccessForm.vue'
import FeatherIcon from '@/components/FeatherIcon.vue'
const VxTour = () => import('@/components/VxTour.vue')

export default {
  name: 'QuestionnairesForm',
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  components: {
    // draggable,
    EditorPanel,
    QuestionnaireAnswers,
    QuestionnaireResume,
    QuestionnairePublication,
    datetime: Datetime,
    ComplexEditor,
    DisciplineForm,
    ScoreLevelConfig,
    FormWizard,
    TabContent,
    VxTour,
    // HelpIcon,
    DataAccessForm,
    FeatherIcon,
    PlanUsageAlert
  },
  data() {
    return {
      loading: false,
      service: null,
      disciplineService: null,
      contentQuestionnaireService: null,
      content: null,
      model: {
        name: '',
        discipline: { id: null, title: '', course: null },
        template: { id: null, name: '' },
        description: null,
        duration: null,
        question_duration: 0,
        leave: false,
        hide_question_score: false,
        restricted_publication: false,
        can_pause: false,
        entrance_justification_time: null,
        entrance_time_limit: null,
        entrance_day_limit: null,
        multiple_attempts: false,
        view_results: false,
        view_answers: false,
        view_rank: false,
        disallow_question_return: false,
        single_question: false,
        single_section: false,
        shuffle_questions: false,
        hide_questions: null,
        average_score: null,
        question_score: null,
        score: null,
        password: null,
        progressive_questionnaire: false,
        progressive_questionnaire_count: null,
        facematch: false,
        liveness: false,
        start_at: {
          date: null,
          time: null
        },
        finish_at: {
          date: null,
          time: null
        },
        simple_time : null,
        force_fullscreen: null,
        env_restriction: {
          device: [],
          os: [],
          browser: []
        },
        random_questions: {
          type: null,
          parameters: {
            score: null,
            questions: null,
          },
        },
        voice_control: null,
      },
      second_chance: {
        parameters: {
          min_score: null,
          total: null,
        }
      },
      secondChance: false,
      showDisciplineGrid: false,
      showDisciplineForm: false,
      showTemplateGrid: false,
      showDataAccess: false,
      showPopupScoreLevelConfig: false,
      showPopupRandomQuestionsConfig: false,
      keyPopupRandomQuestionsConfig: 0,
      editorData: '',
      tabIndex: 0,
      isTabEnabled: false,
      key: 0,
      configCollapsed: false,
      questionnaireVideoIntroSteps: false,
      questions: [],
      showScoreLevelConfig: false,
      scoreLevelList : []
    }
  },
  computed: {
    validateForm() {
      return (
        !this.isEmpty(this.model.name)
      )
    },
    contentData() {
      return this.content && this.content.data
        ? JSON.parse(this.content.data)
        : null
    },
    contentId() {
      return this.content ? this.content.id : null
    },
    disciplineName() {
      if (!this.isEmpty(this.model.discipline)) {
        return `${this.model.discipline.title} ${this.model.discipline.course ?  ` - ${  this.model.discipline.course.name}` : ''}`
      }
      return ''
    },
    templateName() {
      if (!this.isEmpty(this.model.template)) {
        return this.model.template.name
      }
      return ''
    },
    startAt() {
      if (this.model.start_at.date && this.model.start_at.time) {
        const startAtDate = new Date(this.model.start_at.date)
        const startAtTime =  new Date(this.model.start_at.time)

        const startAt = (new Date())
        startAt.setUTCFullYear(
          startAtDate.getFullYear(),
          startAtDate.getMonth(),
          startAtDate.getDate()
        )

        startAt.setUTCHours(startAtTime.getHours())
        startAt.setUTCMinutes(startAtTime.getMinutes())
        startAt.setUTCSeconds(0)
        startAt.setUTCMilliseconds(0)

        return `${startAt.toISOString().slice(0, -1)}`
      } else { return null }
    },
    finishAt() {
      if (this.model.finish_at.date && this.model.finish_at.time) {
        const finishAtDate = new Date(this.model.finish_at.date)
        const finishAtTime =  new Date(this.model.finish_at.time)

        const finishAt = (new Date())
        finishAt.setUTCFullYear(
          finishAtDate.getFullYear(),
          finishAtDate.getMonth(),
          finishAtDate.getDate()
        )

        finishAt.setUTCHours(finishAtTime.getHours())
        finishAt.setUTCMinutes(finishAtTime.getMinutes())

        if (finishAtTime.getHours() === 23 && finishAtTime.getMinutes() === 59) {
          finishAt.setUTCSeconds(59)
        } else {
          finishAt.setUTCSeconds(0)
        }

        return `${finishAt.toISOString().slice(0, -1)}`
      } else { return null }
    },
    showBrowserSelect() {
      const devices = this.model.env_restriction.device
      if (this._.isArray(devices)) {
        return devices.includes('web')
      }
      return false
    },
    showOSSelect() {
      const devices = this.model.env_restriction.device
      if (this._.isArray(devices)) {
        return devices.includes('desktop')
      }
      return false
    },
    hasForceFullscreen() {
      return this.model.force_fullscreen !== null
    },
    showActionOpenPopupRandomThemeConfig(){
      const isTheme = this._.get(this.model.random_questions, 'type') === 'theme'
      return isTheme
    }
  },
  watch: {
    'model.force_fullscreen'(value, old) {
      if (!this.isEmpty(value) && this.isEmpty(old)) {
        this.forceFullScreenValidate()
      }
    },
    showPanel() {
      return this.content !== null
    },
    questionnaireVideoIntroSteps() {
      if (!this.questionnaireVideoIntroSteps && !this.tourStarted('questionnaireIntroSteps')) {
        this.configCollapsed = false
        this.startTour('questionnaireIntroSteps')
      }
    },
    'model.env_restriction.device'(devices, old) {
      if (this._.isArray(devices)) {
        if (!devices.includes('web')) {
          this.model.env_restriction.browser = []
        }
        if (!devices.includes('desktop')) {
          this.model.env_restriction.os = []
        }

      }

      if (old.length === 0 && this.hasForceFullscreen) {
        this.forceFullScreenValidate()
      }
    },
    showScoreLevelConfig(valueNew, valueOld){
      if(!valueNew){
        this.scoreLevelList = []
      }
    }
  },
  methods: {
    forceFullScreenValidate() {
      if (this.loading) return
      this.model.env_restriction = {
        browser: [
          'firefox',
          'chrome',
          'opera'
        ],
        device: [
          'web',
          'desktop'
        ],
        os: [
          'windows',
          'linux',
          'macos'
        ]
      }

      this.$vs.dialog({
        color: 'warning',
        title: this.$t('atencao'),
        acceptText: 'OK',
        text: this.$t('o-bloqueio-de-tela-nao-funciona-para-dis'),
      })
    },
    save() {
      this.clearErrors(this.$validator)
      this.$vs.loading()

      // We dont want to be passing empty arrays on this config to the backend
      // So we transform any empty array value into null.
      const envRestriction = ((restrictions) => {
        const hasDevices = restrictions.device ? restrictions.device.length !== 0 : false
        const hasBrowsers = restrictions.browser ? restrictions.browser.length !== 0 : false
        const hasOses = restrictions.os ? restrictions.os.length !== 0 : false
        if (hasDevices) return {
          ...restrictions,
          os: restrictions.os && restrictions.os.length > 0 ? restrictions.os : [],
          browser: restrictions.browser && restrictions.browser.length > 0 ? restrictions.browser : []
        }
        else return null
      })(this.model.env_restriction)

      const randomQuestions = ((random_questions) => {
        if (random_questions) {
          const type = this._.get(random_questions, 'type', null)

          const isBasic = type === 'basic'
          const isScore = type === 'score'
          const isDifficulty = type === 'difficulty'
          const isTheme = type === 'theme'

          let object = {}
          if (isBasic || isScore || isDifficulty || isTheme) {
            if(!isTheme){
              delete random_questions.themes
            }

            return random_questions
          }
        }

        return null
      })(this.model.random_questions)

      const form = {
        id: this.id,
        name: this.model.name,
        discipline_id: this.model.discipline.id,
        template_id: this.model.template.id,
        description: this.editorData,
        duration: this.model.duration,
        question_duration: this.model.question_duration,
        leave: this.model.leave,
        hide_question_score: this.model.hide_question_score,
        restricted_publication: this.model.restricted_publication,
        can_pause: this.model.can_pause,
        entrance_justification_time: this.model.entrance_justification_time,
        entrance_time_limit: this.model.entrance_time_limit,
        entrance_day_limit: this.model.entrance_day_limit,
        multiple_attempts: this.model.multiple_attempts,
        view_results: this.model.view_results,
        view_answers: this.model.view_answers,
        view_rank: this.model.view_rank,
        single_question: this.model.single_question,
        single_section: this.model.single_section,
        disallow_question_return: this.model.disallow_question_return,
        shuffle_questions: this.model.shuffle_questions,
        hide_questions: this.model.hide_questions,
        average_score: this.model.average_score,
        score: this.model.score,
        question_score: this.model.question_score,
        password: this.model.password,
        start_at: this.startAt,
        finish_at: this.finishAt,
        force_fullscreen: this.model.force_fullscreen,
        env_restriction: envRestriction,
        random_questions: randomQuestions,
        voice_control: this.model.voice_control,
        progressive_questionnaire: this.model.progressive_questionnaire,
        progressive_questionnaire_count: this.model.progressive_questionnaire_count,
        score_level: this.scoreLevelList,
        facematch: this.model.facematch,
        liveness: this.model.liveness,
        second_chance: this.secondChance ? this.second_chance : null
      }
      this.service.createOrUpdate(form).then(
        data => {
          this.$vs.notify({
            title: 'Successo!',
            text: this.$t('operacao-realizada-com-sucesso'),
            color: 'success',
            iconPack: 'feather',
            position: 'top-center',
            icon: 'icon-check-circle'
          })
          this.content = data
          this.key++
          this.$vs.loading.close()
          if (this.isEmpty(this.id)) {
            this.$router.push(`/questionnaires/${data.id}/edit`)
          } else if (this.questions.length === 0 && this.$refs.formWizard) {
            this.$refs.formWizard.nextTab()
          }
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-salvar-o-questionario-v'))
          this.$vs.loading.close()
        }
      )
    },
    handleDisciplineSelection(discipline) {
      this.model.discipline.id = discipline.id
      this.model.discipline.title = discipline.title
      this.model.discipline.course = discipline.course
      this.showDisciplineGrid = false
    },
    setDiscipline() {
      if (arguments.length === 2 && !this.isEmpty(arguments[0])) {
        this.model.discipline.id = arguments[0]
        this.model.discipline.title = arguments[1]
      } else {
        this.model.discipline.id = null
        this.model.discipline.title = ''
      }
    },
    handleTemplateSelection(template) {
      this.model.template.id = template.id
      this.model.template.name = template.name
      this.showTemplateGrid = false
    },
    setTemplate() {
      if (arguments.length === 2 && !this.isEmpty(arguments[0])) {
        this.model.template.id = arguments[0]
        this.model.template.name = arguments[1]
      } else {
        this.model.template.id = null
        this.model.template.name = ''
      }
    },
    shortTitle(title) {
      return this.truncate(this.html2text(title), 100)
    },
    onlyOne(value, first, second) {
      if (value) {
        this.$set(this.model, first, value)
        this.$set(this.model, second, false)
      }
    },
    changedPerQuestion(value) {
      this.onlyOne(value, 'single_question', 'single_section')
      if (!value) {
        this.atLeast(this.model.disallow_question_return, 'should_have_at_least_one_grouping', 'single_question', 'single_section')
      }
    },
    changedPerSection(value) {
      this.onlyOne(value, 'single_section', 'single_question')
      if (!value) {
        this.atLeast(this.model.disallow_question_return, 'should_have_at_least_one_grouping', 'single_question', 'single_section')
      }
    },
    enforceCanContinue(value) {
      if (value) {
        if (!this.model.leave) {
          this.model.leave = true
          this.model.multiple_attempts = false
          this.notifyWarning(
            this.$vs,
            this.$t('para-essa-configuracao-funcionar-o-quest'),
            7000
          )
        }
      }
    },
    atLeast(value, i18n, ...fields) {
      if (value) {
        const atLeastOne = fields.some((field) => {
          return this.model[field] === true
        })
        if (!atLeastOne) {
          this.notifyWarning(
            this.$vs,
            this.$t(i18n),
            5000
          )
          setTimeout(() => {
            this.model.disallow_question_return = false
          }, 600)
        }
      }
    },
    cancel() {
      this.$router.push('/questionnaires')
    },

    setStartAt(dateObj) {
      if (!this.isEmpty(dateObj)) {
        dateObj = new Date(dateObj)
        const date = dateObj.toISOString()
        this.model.start_at.date = date
        this.model.start_at.time = date
      }
    },

    setFinishAt(dateObj) {
      if (!this.isEmpty(dateObj)) {
        dateObj = new Date(dateObj)
        const date = dateObj.toISOString()
        this.model.finish_at.date = date
        this.model.finish_at.time = date
      }
    },

    startChanged(field) {
      if (field === 'date' && this.isEmpty(this.model.start_at.time)) {
        this.model.start_at.time = (new Date()).toISOString()
      } else if (this.isEmpty(this.model.start_at.date)) {
        this.model.start_at.date = this.model.start_at.time
      }
    },

    finishChanged(field) {
      if (field === 'date' && this.isEmpty(this.model.finish_at.time)) {
        this.model.finish_at.time = '2021-01-30T23:59:59.999-03:00'
      } else if (this.isEmpty(this.model.finish_at.date)) {
        this.model.finish_at.date = this.model.finish_at.time
      }
    },
    ckeckVideoClosed() {
      return this.questionnaireVideoIntroSteps
    },
    tabChanged(prevIndex, nextIndex) {
      if (prevIndex === 1) {
        if (this.$refs.editorPanel) {
          this.$refs.editorPanel.disableSmartScroll()
        }
      }
      switch (nextIndex) {
      case 0:
        this.$store.dispatch('common/setCurrentTour', 'questionnaireIntroSteps')
        break
      case 1:
        this.$store.dispatch('common/setCurrentTour', 'questionnaireEditorIntroSteps')
        if (!this.isEmpty(this.$refs.editorPanel)) {
          this.$refs.editorPanel.enableSmartScroll()
        }
        break
      case 2:
        this.$store.dispatch('common/setCurrentTour', 'questionnairePublicationIntroSteps')
        break
      case 3:
        this.$store.dispatch('common/setCurrentTour', 'questionnaireAnswersIntroSteps')
        break
      case 4:
        this.$store.dispatch('common/setCurrentTour', 'questionnaireResumeIntroSteps')
        break
      default:
        break
      }
    },
    addScoreLevel(model){
      this.scoreLevelList.push(model)
    },
    deleteScoreLevel(index){
      this.scoreLevelList.splice(index, 1)
    },
    openPopupRandomQuestionsConfig(){
      this.showPopupRandomQuestionsConfig = true
      this.openThemeConfig()
    },
    openThemeConfig(){
      this.$vs.loading()
      this.service.getThemesConfig(this.id).then(
        data => {
          this.handlingThemesConfig(data)
          this.keyPopupRandomQuestionsConfig++ //Update/force popup render with data
        },
        error => {
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-carregar-tema-para-config')
          )
        }
      ).finally(
        () => {this.$vs.loading.close()}
      )

    },
    handlingThemesConfig(themesResponse){
      if(this.model.random_questions.themes && themesResponse){
        themesResponse.forEach(theme => {
          //find in add, para add
          let themeLocal = this.model.random_questions.themes.filter(tLocal =>
            tLocal.id === theme.id
          )

          //Not found
          if(!themeLocal || themeLocal.length == 0){
            this.model.random_questions.themes.push(theme)
          }
        })

        //Remove olds, case remove from questions
        let newList = []
        this.model.random_questions.themes.forEach(tLocal => {
          let tFound = themesResponse.filter(tResponse =>
            tLocal.id === tResponse.id
          )
          if(tFound && tFound.length > 0){
            newList.push(tLocal)
          }
        })
        this.model.random_questions.themes = newList
      }else{
        this.model.random_questions.themes = themesResponse
      }
    }

  },
  beforeMount() {

    this.$store.dispatch('common/setCurrentTour', 'questionnaireIntroSteps')

    // Video intro ckeck
    this.questionnaireVideoIntroSteps = !this.tourStarted('questionnaireVideoIntroSteps', true)
  },
  created() {
    Settings.defaultLocale = 'pt'
    this.service = ContentQuestionnaireService.build(this.$vs)
    this.disciplineService = DisciplineService.build(this.$vs)
    this.contentQuestionnaireTemplateService = ContentQuestionnaireTemplateService.build(this.$vs)
    if (this.id !== null) {
      this.$vs.loading()
      this.loading = true
      return this.service.get(this.id).then(
        data => {

          const questionData = JSON.parse(data.data)
          if (questionData && questionData.fields) {
            this.questions = questionData.fields
          }

          if (this.questions.length === 0 && this.$refs.formWizard) {
            this.$refs.formWizard.nextTab()
          }

          this.model.name = data.name
          this.model.discipline.id = data.discipline_id


          if (data.template) {
            this.model.template.id = data.template.id
            this.model.template.name = data.template.name
          }

          if (!this.isEmpty(this.model.discipline.id) && this.$acl.hasPermission('disciplines.show')) {
            this.disciplineService.get(data.discipline_id).then(
              data => {
                this.model.discipline.title = data.title
              },
              error => {
                this.showErrors(
                  this.$validator,
                  this.$t('erro-ao-buscar-dados-de-disciplina')
                )
                this.$vs.loading.close()
              }
            )
          }
          this.model.description = data.description
          this.editorData =
            this.model.description !== null ? this.model.description : ''
          this.model.duration = data.duration
          this.model.question_duration = data.question_duration
          this.model.leave = data.leave
          this.model.hide_question_score = data.hide_question_score
          this.model.restricted_publication = data.restricted_publication || false
          this.model.can_pause = data.can_pause || false
          this.model.entrance_justification_time = data.entrance_justification_time || null
          this.model.entrance_time_limit = data.entrance_time_limit || null
          this.model.entrance_day_limit = data.entrance_day_limit || null
          this.model.multiple_attempts = data.multiple_attempts
          this.model.view_results = data.view_results
          this.model.view_answers = data.view_answers
          this.model.view_rank = data.view_rank
          this.model.single_question = data.single_question
          this.model.single_section = data.single_section
          this.model.shuffle_questions = data.shuffle_questions
          this.model.average_score = data.average_score
          this.model.hide_questions = data.hide_questions
          this.model.score = data.score
          this.model.question_score = data.question_score
          this.model.password = data.password
          this.model.disallow_question_return = data.disallow_question_return
          this.model.force_fullscreen = data.force_fullscreen
          this.scoreLevelList = this._.get(data, 'score_level') ? this._.get(data, 'score_level') : []
          this.showScoreLevelConfig = this._.get(data, 'score_level') ? this._.get(data, 'score_level').length > 0 : false
          this.model.env_restriction = data.env_restriction || {
            device: [],
            os: [],
            browser: []
          }
          this.model.random_questions = data.random_questions || {
            type: null,
            parameters: {score: null, questions: null}
          }
          this.model.voice_control = data.voice_control
          this.model.progressive_questionnaire = data.progressive_questionnaire
          this.model.progressive_questionnaire_count = data.progressive_questionnaire_count
          this.model.facematch = this._.get(data, 'facematch', false)
          this.model.liveness = this._.get(data, 'liveness', false)
          this.second_chance = this._.get(data, 'second_chance', false) ? this._.get(data, 'second_chance') : this.second_chance
          this.secondChance =  this._.get(data, 'second_chance', false) ? true : false
          this.setStartAt(data.start_at_iso_formatted)
          this.setFinishAt(data.finish_at_iso_formatted)
          this.content = data
          this.isTabEnabled = true
          this.key++
          this.$vs.loading.close()
          this.$nextTick(() => {
            this.loading = false
          })
          this.$root.$emit('questionnaire-edit-ready')
        },
        error => {
          this.showErrors(this.$validator, this.$t('erro-ao-processar-esta-requisicao'))
          this.$vs.loading.close()
          this.$router.push('/questionnaires')
        }
      )
    }

    if (this.id === null) {
      this.configCollapsed = true
    }
  },
  activated() {
    this.$vs.loading.close()
    console.log('activated...', this.model)
  }
}
</script>

<style lang="scss">
.disciplinePopupGrid .vs-con-table .searchbar {
  width: 100% !important;
}

.templatePopupGrid .vs-con-table .searchbar {
  width: 100% !important;
}

.tooltip-max-width {
  max-width: 30rem;
}

</style>

<style lang="scss" scoped>
.vuesax-app-is-ltr .vs-input--label {
  padding-left: none !important;
}

.v-divider {
  border-left: 1px solid #b8c2ccCC;
}

.vs-con-input-label {
  width: auto !important;
}
.input-group-text {
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #eee;
    border-radius: 5px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

</style>
